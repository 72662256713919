import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BypassSecurityTrustHtmlPipe } from '@profilum-pipes/bypass-security-trust-html/bypass-security-trust-html.pipe';
import { ButtonHeightType, ButtonRadiusType, ButtonType } from '@profilum-collections/common.collections';

@Component({
  selector: 'prf-button',
  standalone: true,
  imports: [CommonModule, BypassSecurityTrustHtmlPipe],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() public buttonTitle: string;
  @Input() public icon: string;
  @Input() public isIconRight: boolean;
  @Input() public isCompact: boolean;
  @Input() public disabled: boolean;
  @Input() public type: ButtonType;
  @Input() public isLoading: boolean;
  @Input() public isTransition: boolean;
  @Input() public isFullWidth: boolean;
  @Input() public isButtonTitleBold: boolean = true;
  @Input() public isWithoutPadding: boolean = false;
  @Input() public useCofoSansFont: boolean = false;
  @Input() public isWidthAuto: boolean = false;
  @Input() public heightType: ButtonHeightType = ButtonHeightType.DEFAULT;
  @Input() public radiusType: ButtonRadiusType = ButtonRadiusType.X6;

  @Output() public emitClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  public iconsPath: string = './prf-lib-assets/icons/all-icons.svg#';
  public buttonType: typeof ButtonType = ButtonType;
}
