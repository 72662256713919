import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiProgramsService,
  IProfession,
  ProfessionEducationLevel,
  ProfessionsService,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems
} from '@profilum-library';
import { ModalHandlerService } from "../../../../ui-kit/services/modal-handler/modal-handler.service";

@Component({
  selector: 'prf-steps-trajectory',
  templateUrl: './steps-trajectory.component.html',
  styleUrls: ['./steps-trajectory.component.scss'],
})
export class StepsTrajectoryComponent {
  @Input() hideButton: boolean = false;
  @Input() showMoreAbout: boolean = false;

  @Input()
  set profession(profession: IProfession) {
    this._profession = profession;
    this.currentStep = 'averageSchool';

    if (this._profession.companies) {
      this._profession.companies.filter(x => (x.url = x.url.trim()));
    }
    const specialitiesCodes = profession.specialities.map(speciality => speciality.code);
    const regionId: string = this.userDataHandlerService.getUserData().getValue()?.regionId;

      this.apiProgramsService.getProgramsSpoVpoBySpecialityCodes({
        specialityCodes: specialitiesCodes,
        regionId: regionId
      })
        .subscribe(programs => {
            programs.programsSpoVpo.forEach(program => {
              if (this.programsBySpecialityName[program.specialityName]) {
                this.programsBySpecialityName[program.specialityName].push(program)
              } else
                this.programsBySpecialityName[program.specialityName] = [program]
            })
          }
        );

    this.specialsCollage = profession.specialities.filter(speciality => speciality.educationLevel === ProfessionEducationLevel.Collage);
    this.specialsUniversity = profession.specialities.filter(speciality => speciality.educationLevel === ProfessionEducationLevel.University);

    this.setSchemeType();
  }

  get profession(): IProfession {
    return this._profession;
  }

  public isOpen: boolean = true;
  public currentStep: string = 'averageSchool';
  public specialsCollage: Array<any> = [];
  public specialsUniversity: Array<any> = [];
  public schemeType: string = 'twoBranches'; // [twoBranches, withoutCollege, withoutUniversity]
  public programsBySpecialityName: any = [];

  public userRole: string;
  public selectedSpecial;
  public selectedPrograms;
  private _profession: IProfession;

  @ViewChild('programsSpoVpoTemplate') public programsSpoVpoTemplate: TemplateRef<any>;

  constructor(
    private professionService: ProfessionsService,
    private router: Router,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
    private userDataHandlerService: UserDataHandlerService,
    private apiProgramsService: ApiProgramsService,
    private modalHandlerService: ModalHandlerService,
  ) {
    this.userRole = this.userDataHandlerService.getUserInfo().role;
  }

  public showProfession() {
    this.professionService.setCurrentProfession(this.profession);
    this.router.navigate(['/professions', this.profession.hrid]);
  }

  public showPrograms(special): void {
    if (this.programsBySpecialityName?.[special.name]) {
      this.selectedSpecial = special;
      this.selectedPrograms = this.programsBySpecialityName[special.name];
      this.modalHandlerService.sideModalTemplate.next(this.programsSpoVpoTemplate);
      this.modalHandlerService.setConfirmButton('');
      this.modalHandlerService.toggleSideModal(true);
    }
  }

  public goToCourses(course: any) {
    this.yandexMetricsService.reachGoal(YmItems.S_ProfessionsCard_Courses);
    this.yandexMetricsService.reachGoal(YmItems.T_Professions_Courses);
    this.webStorageService.set(StorageKeys.Course, course);
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'teacher':
      case 'tutor':
        this.router.navigate(['/courses']);
        break;
      default:
        this.router.navigate([`${this.userRole}/courses`]);
        break;
    }
  }

  private setSchemeType() {
    if (this._profession && this._profession.education && this._profession.education.length) {
      if (
        this._profession.education.some(e => e.level === 2) &&
        this._profession.education.some(e => e.level === 3 && e.perentage !== 100)
      ) {
        this.schemeType = 'twoBranches';
        return;
      }

      if (this._profession.education.some(e => e.level === 3 && e.perentage === 100)) {
        this.schemeType = 'withoutCollege';
        return;
      }

      if (!this._profession.education.some(e => e.level === 3)) {
        this.schemeType = 'withoutUniversity';
        return;
      }
    }
  }
}
