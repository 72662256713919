import { Component, Input } from '@angular/core';
import { Gender } from '../../enums/gender.enums';
import { ETestTypesSteps } from '../../enums/testtypes.enum';
import { take } from 'rxjs/operators';
import {
  ApiPlayerService,
  B2gSaasService,
  EmptyGuid,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-parent-test-panel',
  templateUrl: './parent-test-panel.component.html',
  styleUrls: ['./parent-test-panel.component.scss'],
})
export class ParentTestPanelComponent {
  @Input()
  set pupilUserId(val: string) {
    this._pupilUserId = val;
  }

  @Input() parentSessionComplete: boolean = false;
  @Input() set sessionIDParent(val: string) {
    this._sessionIDParent = val;
    if (val) {
      this.loaded = false;
      this.getSession();
    }
  }
  @Input() isPupil: boolean = false;

  public loaded: boolean = true;
  public currentSlideNumber: number = 1;
  public slidesCount: number = ETestTypesSteps.DEFAULT_STANDART;
  public RefferalUserGender: string = '';
  public sessionStatus: string = '';
  public testSessionId: string = '';
  public uncompletedTest: boolean = false;
  public userData: IUserInfo;

  private _sessionIDParent: string = '';
  private _pupilUserId: string = '';

  constructor(
    private apiPlayerService: ApiPlayerService,
    private webStorageService: WebStorageService,
    private router: Router,
    private userDataHandlerService: UserDataHandlerService,
    private b2gSaasService: B2gSaasService,
  ) {
    this.RefferalUserGender = this.webStorageService.get(StorageKeys.RefferalUserGender);
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.userData = this.userDataHandlerService.getUserData().getValue();
    this.b2gSaasService.getLastSession(this.userData.userId).subscribe(reponse => {
      this.testSessionId = reponse.sessionId;
    });
    this.uncompletedTest = this.sessionStatus === 'Uncompleted test';
    if (this.webStorageService.get(StorageKeys.UserRole) === 'parent') {
      this.slidesCount = ETestTypesSteps.DEFAULT_360;
    }
  }

  get progressBarWidth(): number {
    return Math.round(100 - ((this.slidesCount - this.currentSlideNumber) / this.slidesCount) * 100);
  }

  get sessionIDParent() {
    return this._sessionIDParent;
  }

  private getSession() {
    this.loaded = false;
    if (this.sessionStatus !== 'Untested user' && this.sessionIDParent && this.sessionIDParent !== EmptyGuid) {
      this.apiPlayerService
        .getCurrentSlide(this.sessionIDParent)
        .pipe(take(1))
        .subscribe((slide: any) => {
          if (slide?.slide) {
            this.currentSlideNumber = slide.slide.orderNumber;
          }
          this.loaded = true;
        });
    } else {
      this.loaded = true;
    }
  }

  public isMale(gender: string): boolean {
    return gender === Gender.Male;
  }

  public navigateStartTest() {
    this.router.navigate(['/test-na-professiyu']);
  }

  public navigateContinueTest() {
    this.router.navigate(['/test-na-professiyu/' + this.testSessionId]);
  }
}
