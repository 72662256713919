import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ApiAuthService, AppSettingsService, B2gSaasService, Helper, LocationEnum, YmItems } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { CodeInputStateEnum } from '@profilum-components/code-input/code-input.config';
import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import {
  ResetPasswordByPhoneEnum,
  RestorePasswordByPhoneCallTextsEnum,
  RestorePasswordStepsEnum
} from './restore-password.constants';

@Component({
  selector: 'prf-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent extends UnsubscribeComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public userPhoneWithSeparators: string = '';
  public currentStep: RestorePasswordStepsEnum = RestorePasswordStepsEnum.LOGIN_INPUT;
  public codeInputFieldState: CodeInputStateEnum = CodeInputStateEnum.DEFAULT;
  public resetPasswordByPhone: ResetPasswordByPhoneEnum = ResetPasswordByPhoneEnum.PHONE_CALL;
  public failedLimitSms: boolean = false;
  public errorResetPassword: boolean = false;
  public isFree: boolean = false;
  public usernameType: 'phone' | 'email';
  public ymItems = YmItems;
  public isKruzhkiPage: boolean = false;
  public secondsToNextAttemptPhoneCall: number = 180;
  public nextAttemptPhoneCallTimer$: Observable<number>;

  protected readonly CodeInputStateEnum = CodeInputStateEnum;
  protected readonly RestorePasswordStepsEnum = RestorePasswordStepsEnum;
  protected readonly RestorePasswordByPhoneCallTextsEnum = RestorePasswordByPhoneCallTextsEnum;

  @ViewChild('codeInput') public codeInput!: CodeInputComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private meta: Meta,
    private router: Router,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private b2gSaasService: B2gSaasService,
    private appSettings: AppSettingsService,
    private apiAuthService: ApiAuthService,
    private overlayBusyService: OverlayBusyService,
  ) {
    super();
    this.getTranslation('SHARED.PASSWORD_RECOVERY')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      username: new UntypedFormControl(null, [Validators.required]),
    });
    this.isKruzhkiPage = this.appSettings.currentLocation === LocationEnum.KRUZHKI;
    this.initPhoneCallTimer();
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.formGroup.controls;
  }

  public next(): void {
    if (this.formControls.username.valid) {
      this.errorResetPassword = false;
      this.failedLimitSms = false;
      if (REG_EXP.emailRegExp.test(this.formControls.username.value)) {
        this.usernameType = 'email';

        this.b2gSaasService
          .sendResetToken(this.formControls.username.value, this.isKruzhkiPage)
          .pipe(take(1), takeUntil(this.unsubscribe))
          .subscribe(
            token => {
              this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_SMS;
              if (token.status !== 'Success') {
                this.errorResetPassword = true;
              }
            },
            () => {
              this.utilsService.openSnackBar(ServerErrorMessage, 'error');
            },
          );
      }

      if (REG_EXP.phoneRegExp.test(this.formControls.username.value)) {
        this.usernameType = 'phone';

        const phone = Helper.phoneValidation(this.formControls.username.value?.replace(/\D/g, ''));

        if (this.resetPasswordByPhone === ResetPasswordByPhoneEnum.SMS) {
          this.b2gSaasService
            .sendResetTokenToPhone(phone)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
              next: token => {
                if (token) {
                  this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_SMS;

                  if (token.status !== 'Success') {
                    if (token.status === 'Too Many Requests') {
                      this.failedLimitSms = true;
                    } else {
                      this.errorResetPassword = true;
                    }
                  }
                }
              },
              error: () => {
                this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE;
                this.errorResetPassword = true;
              },
            });
        } else {
          this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_INFO_PAGE;
        }
      }
    }
  }

  public back(): void {
    if (this.currentStep === RestorePasswordStepsEnum.LOGIN_INPUT) {
      this.navigateToLogin();
    } else {
      this.currentStep = RestorePasswordStepsEnum.LOGIN_INPUT;
    }
  }

  public navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  private getTranslation(key: string): Observable<string> {
    return this.translateService.get(key);
  }

  public checkLogin(): void {
    this.formControls.username['focused'] = false;
    this.clearLoginSpaces();

    if (!this.isKruzhkiPage && REG_EXP.phoneRegExp.test(this.formControls.username.value)) {
      const phone = Helper.phoneValidation(this.formControls.username.value?.replace(/\D/g, ''));
      if (!Helper.isValidPhoneNumber(phone) || phone.length < 11) {
        this.formControls.username.setErrors({ pattern: true });
        return;
      }

      this.apiAuthService
        .checkPhoneAvailability(phone)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: any) => {
          if (response.free) {
            this.formControls.username.setErrors({ isNotUsed: true });
          }
          this.userPhoneWithSeparators = Helper.parsePhoneNumber(phone.slice(1)).phoneNumberWithSeparators;
        });

      return;
    } else if (REG_EXP.emailRegExp.test(this.formControls.username.value)) {
      const email = this.formControls.username.value;

      if (!Helper.isValidEmail(email)) {
        this.formControls.username.setErrors({ pattern: true });
        return;
      }
      return;
    }

    this.formControls.username.setErrors({ pattern: true });
  }

  public requestCall(isUpdateTimerNeeded: boolean = false): void {
    this.overlayBusyService.show();
    this.codeInputFieldState = CodeInputStateEnum.DEFAULT;

    const phone = Helper.phoneValidation(this.formControls.username.value?.replace(/\D/g, ''));

    this.b2gSaasService
      .requestCall(phone)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_FORM;
          if (isUpdateTimerNeeded) {
            this.initPhoneCallTimer();
          }
          this.overlayBusyService.hide();
        },
        error: () => {
          this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE;
          this.overlayBusyService.hide();
        },
      });
  }

  private initPhoneCallTimer(): void {
    this.nextAttemptPhoneCallTimer$ = timer(0, 1000).pipe(
      map(seconds => (this.secondsToNextAttemptPhoneCall - seconds) * 1000),
      takeWhile(seconds => seconds >= 0),
      takeUntil(this.unsubscribe),
    );
  }

  public validateInputCode(code: string): void {
    const phone = Helper.phoneValidation(this.formControls.username.value?.replace(/\D/g, ''));

    this.b2gSaasService
      .verifyCode(phone, code)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: response => {
          if (response.status === 'Failed') {
            if (response.comment === 'Code is invalid') {
              this.codeInput.reset();
              this.codeInputFieldState = CodeInputStateEnum.ERROR;
            } else {
              this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE;
            }
          } else if (response.status === 'Success' && response.passwordResetToken) {
            this.router.navigate(['/change-password'], { queryParams: { phone, token: response.passwordResetToken } });
            return;
          }

          /*if (response.status === 'exceedAttempts') {
            this.codeInputFieldState = CodeInputStateEnum.DISABLED;
            this.codeInput.reset();
          }*/
        },
        error: () => {
          this.currentStep = RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE;
        },
      });
  }

  public navigateToInputLoginStep(): void {
    this.currentStep = RestorePasswordStepsEnum.LOGIN_INPUT;
    this.userPhoneWithSeparators = '';
    this.formGroup.reset();
  }

  public onCodeInputChanged(): void {
    this.codeInputFieldState = CodeInputStateEnum.DEFAULT;
  }

  public openWidget(event: Event): void {
    Helper.openOnlineConsultantWidget(event);
  }

  get isLoginInvalid() {
    return this.formControls.username.dirty && this.formControls.username.invalid && this.formControls.username.errors;
  }

  public clearLoginSpaces(): void {
    const loginWithoutSpaces: string = this.formControls.username.value?.trim();
    this.formControls.username.setValue(loginWithoutSpaces);
    this.formControls.username.updateValueAndValidity();
  }
}
