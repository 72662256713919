<div class="restriction-banner">
  <div class="restriction-banner-text">
    <div class="restriction-banner-title">
      Увидимся в следующем учебном году
    </div>
    <div class="restriction-banner-desc">
      В текущем учебном году ваш регион не осуществляет работу на платформе "Профилум".
      Доступ к личному кабинету сохранен.
    </div>
  </div>
  <div class="restriction-banner-image">
  </div>
</div>
