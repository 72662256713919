import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'prf-restriction-banner',
  templateUrl: './restriction-banner.component.html',
  styleUrls: ['./restriction-banner.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage],
})
export class RestrictionBannerComponent {
}
