import { Routes } from '@angular/router';
import { ProfessionCardsComponent } from 'app/pages/catalog/profession-page/profession-cards/profession-cards.component';
import { RestorePasswordComponent } from 'app/pages/catalog/settings-page/restore-password/restore-password.component';
import { CourseDetailsComponent } from './pages/catalog/courses-page-without-filters/course-details/course-details.component';
import { CoursesPageComponent } from './pages/catalog/courses-page-without-filters/courses-page.component';
import { FavoritesComponent } from './pages/catalog/courses-page-without-filters/favorites/favorites.component';
import { RecommendedCoursesComponent } from './shared/dashboard/courses/recommended-courses/recommended-courses.component';
import { InternshipCardsComponent } from './pages/catalog/internship-page/internship-cards.component';
import { InternshipCatalogComponent } from './pages/catalog/internship-page/internship-catalog/internship-catalog.component';
import { InternshipDetailsComponent } from './pages/catalog/internship-page/internship-details/internship-details.component';
import { ProfessionDetailsComponent } from './pages/catalog/profession-page/profession-details/profession-details.component';
import { UserProfileComponent } from './pages/catalog/user-profile-page/user-profile.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { PageNewsComponent } from './pages/news-page/page-news.component';
import { Page404Component } from './pages/page-404/page-404.component';
import { Page500Component } from './pages/page-500/page-500.component';
import { PageResultComponent } from './pages/results/result-page/page-result.component';
import { PortfolioPageComponent } from './shared/dashboard/teacher-student-page/portfolio-page/portfolio-page.component';
import { CoursesCatalogComponent } from './shared/dashboard/courses/courses-catalog/courses-catalog.component';
import { RestorePasswordKazahstanComponent } from './pages/catalog/settings-page/restore-password/restore-password-kazahstan/restore-password-kazahstan.component';
import { ChangePasswordKazahstanComponent } from './pages/catalog/settings-page/change-password/change-password-kazahstan/change-password-kazahstan.component';
import { PupilTestsHistoryPageComponent } from './pages/control-panel/pupil/pupil-tests-history-page/pupil-tests-history-page.component';
import { ProfessionMobileCatalogComponent } from './pages/catalog/profession-mobile-page/profession-mobile-catalog/profession-mobile-catalog.component';
import { ProfessionDetailMobileComponent } from './pages/catalog/profession-mobile-page/profession-detail-mobile/profession-detail-mobile.component';
import { PupilMobileEditProfileComponent } from 'app/pages/catalog/pupil-mobile-profile/pupil-mobile-edit-profile/pupil-mobile-edit-profile.component';
import { RegistrationChangePasswordComponent } from './landing/base/registration/registration-change-password/registration-change-password.component';
import { PupilWelcomeComponent } from './pages/control-panel/pupil/onbording/pupil-welcome/pupil-welcome.component';
import { PupilMobileEditUserAvatarComponent } from './pages/catalog/pupil-mobile-profile/pupil-mobile-edit-profile/pupil-mobile-edit-user-avatar/pupil-mobile-edit-user-avatar.component';
import { ProgramsSpoVpoOnboardingComponent } from './pages/control-panel/pupil/onbording/programs-spo-vpo-onbording/programs-spo-vpo-onboarding.component';
import { FittingProfessionsOnbordingComponent } from './pages/control-panel/pupil/onbording/fitting-professions-onbording/fitting-professions-onbording.component';
import { AuthGuard, AuthRoleGuard, BvbGuard, CanLoadPupil, MainGuard } from '@profilum-library';
import { ChatBotComponent } from './pages/control-panel/pupil/onbording/chat-bot/chat-bot.component';
import { NewSpoVpoOnboardingComponent } from './pages/control-panel/pupil/onbording/new-spo-vpo-onboarding/new-spo-vpo-onboarding.component';
import { PlayerV2Component } from '@profilum-components/prf-tests/player/default/player-v2.component';

const loadPupilLk = () => {
  console.log('load pupil?');
  if (window.localStorage.getItem('assignedCourseExists')) {
    return import('app/pages/control-panel/pupil/pupil.module').then(m => m.PupilModule);
  }

  return import('app/pages/control-panel/old-school/pupil/pupil-panel-old-school.module').then(m => m.PupilPanelOldSchoolModule);
};

export const routeConfig: Routes = [
  { path: 'test', component: PlayerV2Component },
  {
    path: 'results',
    component: PageResultComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher', 'schooladmin'] },
  },
  {
    path: 'pupil-tests-history',
    component: PupilTestsHistoryPageComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'courses/:hrid',
    component: CourseDetailsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher', 'schooladmin', 'tutor', 'director', 'admin'] },
  },

  {
    path: 'courses',
    component: CoursesPageComponent,
    canActivate: [MainGuard],
    data: { roles: ['pupil', 'teacher', 'tutor', 'admin'] },
    children: [
      {
        path: '',
        component: RecommendedCoursesComponent,
        outlet: 'recommended-courses',
        data: { isCourses: true },
      },
      {
        path: '',
        component: CoursesCatalogComponent,
        outlet: 'courses-catalog',
        data: { isCourses: true },
      },
    ],
  },
  {
    path: 'events/:hrid',
    component: CourseDetailsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher', 'schooladmin', 'director', 'admin'] },
  },
  {
    path: 'events',
    component: EventsPageComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher'] },
  },
  { path: 'change_password', component: RegistrationChangePasswordComponent },
  { path: 'change_password_kz', component: ChangePasswordKazahstanComponent },
  { path: 'recovery_password', component: RestorePasswordComponent },
  { path: 'recovery_password_kz', component: RestorePasswordKazahstanComponent },
  {
    path: 'discover-professions-mobile',
    loadChildren: () =>
      import('app/pages/catalog/discover-profession-mobile-page/discover-profession-mobile-page.module').then(
        m => m.DiscoverProfessionMobilePageModule,
      ),
    canActivate: [BvbGuard],
  },
  {
    path: 'professions',
    component: ProfessionCardsComponent,
    data: { roles: ['pupil', 'parent', 'teacher', 'tutor'] },
  },
  {
    path: 'professions-mobile',
    loadChildren: () => import('app/pages/catalog/profession-mobile-page/professions-mobile.module').then(m => m.ProfessionsMobileModule),
  },
  {
    path: 'bvb-professions',
    component: ProfessionMobileCatalogComponent,
    data: { roles: ['pupil'] },
  },
  {
    path: 'professions-mobile/:filter',
    component: ProfessionMobileCatalogComponent,
    data: { roles: ['pupil'] },
  },
  {
    path: 'professions/:hrid',
    component: ProfessionDetailsComponent,
    data: { roles: ['pupil', 'parent', 'teacher', 'schooladmin', 'director'] },
  },
  {
    path: 'profession/:hrid',
    component: ProfessionDetailMobileComponent,
    data: { roles: ['pupil'] },
  },
  {
    path: 'bvb-profession/:hrid',
    component: ProfessionDetailMobileComponent,
    data: { roles: ['pupil'] },
  },
  {
    path: 'internships',
    component: InternshipCardsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher'] },
  },
  {
    path: 'internships/:hrid',
    component: InternshipDetailsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher'] },
  },
  {
    path: 'internships',
    component: InternshipCardsComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'teacher'] },
    children: [
      {
        path: '',
        component: InternshipCatalogComponent,
        outlet: 'internship-catalog',
      },
    ],
  },
  {
    path: 'portfolio',
    component: PortfolioPageComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'my-favorites',
    component: FavoritesComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil', 'parent'] },
  },
  {
    path: 'news',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'news/:id',
    component: PageNewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pupil-profile',
    loadChildren: () => import('app/pages/catalog/pupil-mobile-profile/pupil-mobile-profile.module').then(m => m.PupilMobileProfileModule),
  },
  {
    path: 'pupil-programs',
    loadChildren: () =>
      import('app/pages/catalog/pupil-mobile-profile/pupil-training-programs/pupil-training-programs.module').then(
        m => m.PupilTrainingProgramsModule,
      ),
  },
  {
    path: 'interests-fitting-programs',
    loadChildren: () =>
      import('app/pages/catalog/pupil-fitting-professions/pupil-fitting-professions/pupil-fitting-professions.module').then(
        m => m.PupilFittingProfessionsModule,
      ),
  },
  {
    path: 'abilities-fitting-programs',
    loadChildren: () =>
      import('app/pages/catalog/pupil-fitting-professions/pupil-fitting-professions/pupil-fitting-professions.module').then(
        m => m.PupilFittingProfessionsModule,
      ),
  },
  {
    path: 'ranging-fitting-programs',
    loadChildren: () =>
      import('app/pages/catalog/pupil-fitting-professions/pupil-fitting-ranging/pupil-fitting-ranging.module').then(
        m => m.PupilFittingRangingModule,
      ),
  },
  {
    path: 'ranging-fitting-industry',
    loadChildren: () =>
      import('app/pages/catalog/pupil-fitting-professions/pupil-fitting-industry/pupil-fitting-industry.module').then(
        m => m.PupilFittingIndustryModule,
      ),
  },
  {
    path: 'fitting-industry-results',
    loadChildren: () =>
      import(
        'app/pages/catalog/pupil-fitting-professions/pupil-fitting-industry/fitting-industry-results/fitting-industry-results.module'
      ).then(m => m.FittingIndustryResultsModule),
  },
  {
    path: 'results-diagnostic',
    loadChildren: () => import('app/ui-kit/components/results-diagnostic/results-diagnostic.module').then(m => m.ResultsDiagnosticModule),
  },
  {
    path: 'ranging-programs-results',
    loadChildren: () =>
      import('app/pages/catalog/pupil-fitting-professions/pupil-ranging-results/pupil-ranging-results.module').then(
        m => m.PupilRangingResultsModule,
      ),
  },
  {
    path: 'pupil-welcome',
    component: PupilWelcomeComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'chat',
    component: ChatBotComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'updated-spo-vpo-onboarding',
    component: NewSpoVpoOnboardingComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  // TODO: роут для тестирования, удалить как будет внедрен подход
  {
    path: 'onboarding-spo-vo',
    component: ProgramsSpoVpoOnboardingComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  // TODO: роут для тестирования, удалить как будет внедрен подход
  {
    path: 'test-onbording-fitting-professions',
    component: FittingProfessionsOnbordingComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'holidays-onboarding',
    loadChildren: () =>
      import('app/pages/control-panel/pupil/onbording/holidays-onboarding/holidays-onboarding.module').then(
        m => m.HolidaysOnboardingModule,
      ),
  },
  {
    path: 'pupil-fitting-abilities',
    loadChildren: () =>
      import('app/pages/control-panel/pupil/onbording/fitting-professions-abilities/fitting-professions-abilities.module').then(
        m => m.FittingProfessionsAbilitiesModule,
      ),
  },
  {
    path: 'fitting-industry-selection',
    loadChildren: () =>
      import('app/pages/control-panel/pupil/onbording/fitting-industry-selection/fitting-industry-selection.module').then(
        m => m.FittingIndustrySelectionModule,
      ),
  },
  {
    path: 'passed-diagnostics-interests',
    loadChildren: () =>
      import('app/pages/control-panel/pupil/onbording/passed-diagnostics/passed-diagnostics.module').then(m => m.PassedDiagnosticsModule),
  },
  {
    path: 'passed-diagnostics-capabilities',
    loadChildren: () =>
      import('app/pages/control-panel/pupil/onbording/passed-diagnostics/passed-diagnostics.module').then(m => m.PassedDiagnosticsModule),
  },
  {
    path: 'pupil-edit-profile',
    component: PupilMobileEditProfileComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'pupil-edit-photo',
    component: PupilMobileEditUserAvatarComponent,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  {
    path: 'pupil',
    canLoad: [CanLoadPupil],
    loadChildren: loadPupilLk,
    canActivate: [AuthRoleGuard],
    data: { roles: ['pupil'] },
  },
  { path: '500', component: Page500Component },
  { path: '404', component: Page404Component },
  { path: '**', redirectTo: '/404' },
];
