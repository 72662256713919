import { finalize, takeUntil } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiCoursesMaterialsService,
  B2gTinderService,
  IData,
  IStorageHelper,
  IUserInfo,
  StorageHelper,
  StorageKeys,
  Storages,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { IPopupNotificationData } from '@profilum-components/notification-popup/ipopupdata.interface';

import { IProcessedProfession, IProfessionCard, IProfessionsTaskActivity, ITab, TabStatus } from './choose-profession.interface';

@Component({
  templateUrl: './choose-profession.component.html',
  styleUrls: ['./choose-profession.component.scss'],
})
export class ChooseProfessionComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('professionCard')
  public professionCard: ElementRef;

  public userData: IUserInfo;
  public cardsToggle: boolean = false;
  public professions: IProcessedProfession[];
  public counter: number = 0;
  public title: string = '';
  public tabs: Map<string, ITab> = new Map<string, ITab>();
  public loading: boolean = true;
  public loadingTinder: boolean = false;
  public currentProfession: IProfessionCard;
  public professionSnapshot: IProfessionCard;
  public currentProfessionFavourite: string;
  public selectedTab: { key?: string; value?: ITab } = {};
  public professionIndex: number = 0;
  public modalNotification: IPopupNotificationData;

  public mathAbs = Math.abs;
  private processing: boolean = false;
  private storageHelper: IStorageHelper;

  constructor(
    private activatedRoute: ActivatedRoute,
    private overlayBusyService: OverlayBusyService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private webStorageService: WebStorageService,
    private userActionsService: UserActionsService,
    private userDataHandlerService: UserDataHandlerService,
    private router: Router,
    private translateService: TranslateService,
    private tinderService: B2gTinderService,
  ) {
    super();
    this.storageHelper = new StorageHelper(Storages.Local);

    this.professions = this.activatedRoute.snapshot.data.professions ?? [];
    this.userData = this.userDataHandlerService.getUserData().getValue();
  }

  public ngOnInit(): void {
    if (this.professions?.length) {
      this.title = this.professions[0].fields[0].name;
      this.createProfessionsSortedList();
      this.formTabs();
      this.mapProcessedProfessions();
      this.setDurationTimeForMetrics(true);
    } else {
      const tinderType: number = this.storageHelper.get(StorageKeys.TinderType);
      if (tinderType) {
        this.loadingTinder = true;
        this.timer = setTimeout(() => {
          this.tinderService
            .getProfessions({ tinderType })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(professions => {
              if (professions?.professions?.length) {
                this.professions = professions?.professions;
                this.title = this.professions[0].fields[0].name;
                this.createProfessionsSortedList();
                this.formTabs();
                this.mapProcessedProfessions();
                this.setDurationTimeForMetrics(true);
              } else {
                this.showNotification();
              }
              this.loadingTinder = false;
            });
        }, 2000);
      } else {
        this.showNotification();
      }
    }
  }

  public get isTabsEmpty(): boolean {
    return this.tabs.size === 0;
  }

  public onSelectNewTab(event): void {
    // При выборе таба вручную, обновляем статус текущего таба,
    // находим новый таб, делаем его активным.
    this.selectedTab.value.status = TabStatus.ENABLED;
    this.selectedTab = { key: event, value: this.tabs.get(event) };
    this.selectedTab.value.status = TabStatus.ACTIVE;

    // Устанавливаем выбранной первую профессию с нужным блоком отрасли
    const profession: IProcessedProfession = this.professions.find((profession: IProcessedProfession, index: number): boolean => {
      this.professionIndex = index;
      return profession.fieldBlock === event;
    });
    this.setCurrentProfession(profession);
  }

  private setCurrentProfession(profession: IProcessedProfession, makeSnapshot: boolean = false): void {
    if (makeSnapshot) {
      this.professionSnapshot = this.currentProfession;
      const status: string = this.currentProfession.isFavourite ? 'like' : 'dislike';
      this.animationControl(status);
    }
    const profImage: string = profession.images.length > 1 ? profession.images[1]?.path : profession.images[0]?.path;
    this.currentProfession = {
      image: profImage,
      title: profession.name,
      content: profession.longDescription,
      isFavourite: profession.favourite,
    };
  }

  private animationControl(status: string): void {
    this.currentProfessionFavourite = status;
    this.cardsToggle = true;
    setTimeout(() => {
      this.currentProfessionFavourite = 'default';
      this.cardsToggle = false;
    }, 1000);
  }

  public onAction(isFavourite: boolean): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    const uaParams: IData = {
      ProfessionId: this.professions[this.professionIndex].id,
      TestId: this.webStorageService.get(StorageKeys.TaskId),
    };
    const ymParams: IData = {
      event_label: { Profession: this.professions[this.professionIndex].id, Task: this.webStorageService.get(StorageKeys.TaskId) },
    };

    if (isFavourite) {
      this.userActionsService.log(YmItems.S_lessons_Exercise_Like, uaParams, ymParams);
      this.userActionsService.log(YmItems.S_lessons_Likes, uaParams, ymParams);
    } else {
      this.userActionsService.log(YmItems.S_lessons_Exercise_NotLike, uaParams, ymParams);
    }

    // Отправить данные о лайке/дизлайке
    if (this.professions[this.professionIndex].id) {
      const processedProfessions = {
        item1: this.professions[this.professionIndex].id,
        item2: isFavourite.toString(),
      };
      this.apiCoursesMaterialsService
        .addProfessionToTaskActivity(
          this.webStorageService.get(StorageKeys.CourseId),
          this.webStorageService.get(StorageKeys.LessonId),
          this.webStorageService.get(StorageKeys.TaskId),
          this.userData.userId,
          processedProfessions,
        )
        .pipe(
          takeUntil(this.unsubscribe),
          finalize(() => this.updateState(isFavourite)),
        )
        .subscribe({
          next: () => {},
          error: (error: Error): void => {
            this.processing = false;
            console.error(error);
          },
        });
    }
  }

  private updateState(isFavourite: boolean): void {
    this.currentProfession.isFavourite = isFavourite;

    // Если это первый проход по профессии, отразить это в модели;
    // дополнительно - увеличить прогресс бар и уменьшить счетчик таба
    this.counter += 1;
    if (!this.professions[this.professionIndex].processed) {
      this.professions[this.professionIndex].processed = true;
      if (this.selectedTab.value) {
        this.selectedTab.value.counter -= 1;
      }
    }

    // если это была последняя профессия, завершаем прохождение задания
    if (this.counter === this.professions.length) {
      // Все профессии задания пройдены
      this.tabs.clear();
      this.apiCoursesMaterialsService
        .setPassed(
          this.userData.userId,
          this.webStorageService.get(StorageKeys.CourseId),
          this.webStorageService.get(StorageKeys.LessonId),
          this.webStorageService.get(StorageKeys.SchoolClassId),
          this.webStorageService.get(StorageKeys.TaskId),
          true,
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          const uaParams: IData = {
            TestId: this.webStorageService.get(StorageKeys.TaskId),
          };
          const ymParams: IData = {
            event_label: { Task: this.webStorageService.get(StorageKeys.TaskId) },
          };
          this.userActionsService.log(YmItems.S_lessons_CompleteExercise, uaParams, ymParams);
        });
      this.webStorageService.set(StorageKeys.UpdateFavouriteProfessions, true);
      this.router.navigate(['/results-diagnostic/' + this.title]);
      return;
    }

    // Если в табе закончились профессии, помечаем его пройденным и переходим к следующему
    if (!this.isTabsEmpty && this.selectedTab.value.counter === 0) {
      this.selectedTab.value.status = TabStatus.ENABLED;
      this.professionIndex += 1;
      this.setCurrentProfession(this.professions[this.professionIndex]);
      this.selectedTab = {
        key: this.professions[this.professionIndex].fieldBlock,
        value: this.tabs.get(this.professions[this.professionIndex].fieldBlock),
      };
      this.selectedTab.value.status = TabStatus.ACTIVE;
      this.scrollTabsContainer(true);
      this.processing = false;
      return;
    }

    this.professionIndex += 1;
    this.setCurrentProfession(this.professions[this.professionIndex], true);
    this.processing = false;
    this.setDurationTimeForMetrics();
  }

  public onSendBack(): void {
    const uaParams: IData = {
      ProfessionId: this.professions[this.professionIndex].id,
      TestId: this.webStorageService.get(StorageKeys.TaskId),
    };
    const ymParams: IData = {
      event_label: { Profession: this.professions[this.professionIndex].id, Task: this.webStorageService.get(StorageKeys.TaskId) },
    };

    if (this.professionIndex > 0) {
      this.professionIndex -= 1;
      this.setCurrentProfession(this.professions[this.professionIndex]);
      this.counter -= 1;
      uaParams.ProfessionPreviousId = this.professions[this.professionIndex].id;
      this.userActionsService.log(YmItems.S_lessons_Exercise_Back, uaParams, ymParams);

      // Если предыдущая профессия относится к другому блоку, делаем его активным
      if (!this.isTabsEmpty && this.professions[this.professionIndex].fieldBlock !== this.selectedTab.key) {
        this.selectedTab.value.status = TabStatus.ENABLED;
        this.selectedTab = {
          key: this.professions[this.professionIndex].fieldBlock,
          value: this.tabs.get(this.professions[this.professionIndex].fieldBlock),
        };
        this.selectedTab.value.status = TabStatus.ACTIVE;
      }
      this.setDurationTimeForMetrics();
    }
  }

  public toggleNotification(): void {
    this.router.navigate(['/pupil']);
  }

  private createProfessionsSortedList(): void {
    const cyrillicNamesDesc: IProcessedProfession[] = this.sortProfessions(/[А-Яа-я]/);
    const latinNamesDesc: IProcessedProfession[] = this.sortProfessions(/[A-Za-z]/);
    const otherNamesDesc: IProcessedProfession[] = this.sortProfessions(/[^А-Яа-яA-Za-z]/);
    this.professions = [...cyrillicNamesDesc, ...latinNamesDesc, ...otherNamesDesc];
  }

  private sortProfessions(regExp: RegExp): IProcessedProfession[] {
    return this.professions
      .filter((prof: IProcessedProfession) => regExp.test(prof.fieldBlock?.[0]))
      .sort((a: IProcessedProfession, b: IProcessedProfession) => a.fieldBlock?.localeCompare(b.fieldBlock));
  }

  private formTabs(): void {
    const defaultTabContent: ITab = {
      amount: 1,
      counter: 1,
      status: TabStatus.DISABLED,
    };
    this.professions.forEach((profession: IProcessedProfession) => {
      // Не отображаем табы вообще, если у какой-либо профессии отрасли отсутствует блок
      if (!profession.fieldBlock) {
        this.tabs.clear();
        return;
      }
      // Если таб уже существует, обновить его значение. иначе - создать таб
      if (this.tabs.has(profession.fieldBlock)) {
        this.tabs.get(profession.fieldBlock).amount += 1;
        this.tabs.get(profession.fieldBlock).counter += 1;
      } else {
        this.tabs.set(profession.fieldBlock, { ...defaultTabContent });
      }
    });
  }

  private mapProcessedProfessions(): void {
    // получаем данные по просмотренным ранее профессиям, обновляем массив профессий
    this.apiCoursesMaterialsService
      .getProfessionsTasksActivities(this.userData.userId, this.webStorageService.get(StorageKeys.LessonId))
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => this.setInitialValue()),
      )
      .subscribe((taskActivity: IProfessionsTaskActivity) => {
        if (taskActivity) {
          const processedProfessions: [string, boolean][] = Object.entries(taskActivity.processedProfessions);
          // если обработаны не все професии, помечаем пройденные карточки просмотренными
          if (processedProfessions.length < this.professions.length) {
            this.setFavouriteProfessionsIntoList(processedProfessions, false);
          } else {
            // если ранее были пройдены все профессии, выставляем имеющимеся реакции и начинаем с начала списка
            this.setFavouriteProfessionsIntoList(processedProfessions, true);
          }
        }
      });
  }

  private setFavouriteProfessionsIntoList(professionsList: [string, boolean][], isFinishedBefore: boolean): void {
    return professionsList.forEach(([key, value]): void => {
      const profession: IProcessedProfession = this.professions.find((profession: IProcessedProfession) => profession.id === key);
      profession ? (profession.favourite = value) : null;
      !isFinishedBefore && profession ? (profession.processed = true) : null;
    });
  }

  private setInitialValue(): void {
    this.professions.some((profession: IProcessedProfession, index: number) => {
      if (profession.processed) {
        return;
      }

      if (this.tabs.size > 0) {
        [...this.tabs.entries()].some((value: [string, ITab]) => {
          // value[0] - имя таба
          if (value[0] === profession.fieldBlock) {
            return true;
          }
          // value[1] - внутренние свойства таба
          // все предшествующие табы помечаем пройденными
          value[1].counter = 0;
          value[1].status = TabStatus.ENABLED;
        });

        // устанавливаем значения, соответствующие найденной профессии
        this.tabs.get(profession.fieldBlock).status = TabStatus.ACTIVE;
        // TODO: обработка каунтера для таба, когда нужная професия не первая в табе

        this.selectedTab = {
          key: profession.fieldBlock,
          value: this.tabs.get(profession.fieldBlock),
        };
      }

      this.professions.map((profession: IProcessedProfession) => {
        profession.state = 'default';
        return profession;
      });

      this.setCurrentProfession(profession);
      this.counter = index;
      this.professionIndex = index;
      return true;
    });
    this.loading = false;
    this.overlayBusyService.hide();
  }

  // скролл к следующему/предыдущему табу
  private scrollTabsContainer(forward: boolean): void {
    // ширина таба + отступ
    const elWidth: number = document.querySelector('.prof-tab_active')?.scrollWidth + 8;
    const el: Element = document.querySelector('.prof-navigation__tabs');
    if (elWidth) {
      el.scrollBy(forward ? elWidth : -elWidth, 0);
    }
  }

  private setDurationTimeForMetrics(firstTime?: boolean): void {
    if (firstTime) {
      this.userActionsService.setInitializationTime([YmItems.S_lessons_CompleteExercise]);
    }

    this.userActionsService.setInitializationTime([
      YmItems.S_lessons_Exercise_Like,
      YmItems.S_lessons_Exercise_NotLike,
      YmItems.S_lessons_Exercise_Back,
      YmItems.S_lessons_Likes,
    ]);
  }

  private showNotification(): void {
    this.overlayBusyService.hide();
    this.translateService
      .get('SHARED.NOTIFICATION_POPUP.TEST_ERROR')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((value: IPopupNotificationData) => {
        this.modalNotification = value;
      });
  }
}
