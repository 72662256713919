import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAuthService,
  ApiCoursesMaterialsService,
  ApiUsersService,
  AppSettingsService,
  AuthHandlerService,
  B2gSaasService,
  ISchoolPortalUserData,
  IUserInfo,
  IUserMurmanskInterface,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { LoginBase } from 'app/shared/auth-classes/login.base';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

export enum loginCasesSP {
  LOGIN,
  REGISTER,
  UPDATE,
}

@Component({
  selector: 'prf-login-murmansk',
  templateUrl: './login-murmansk.component.html',
  styleUrls: ['./login-murmansk.component.scss'],
})
export class LoginMurmanskComponent extends LoginBase<B2gSaasService> implements OnInit {
  private authCode: string = null;
  public userData: IUserMurmanskInterface;

  public loginCasesSP = loginCasesSP;
  public murmanskUser: any = loginCasesSP.LOGIN;

  ngUnsubscribe$ = new Subject<any>();

  constructor(
    meta: Meta,
    translateService: TranslateService,
    overlayService: OverlayBusyService,
    loginService: B2gSaasService,
    b2gSaasService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    appSettings: AppSettingsService,
    @Inject(DOCUMENT) document: Document,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private apiAuthService: ApiAuthService,
    public userDataHandlerService: UserDataHandlerService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    apiCoursesMaterialsService: ApiCoursesMaterialsService,
    apiUsersService: ApiUsersService,
    authHandlerService: AuthHandlerService,
  ) {
    super(
      meta,
      translateService,
      overlayService,
      loginService,
      b2gSaasService,
      router,
      fb,
      document,
      webStorageService,
      userStorageService,
      appSettings,
      apiCoursesMaterialsService,
      apiUsersService,
      authHandlerService,
    );

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      this.authCode = params['code'];
    });
  }

  public ngOnInit(): void {
    if (this.authCode) {
      this.getBarsUserData(this.authCode).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
    }
    super.ngOnInit();
  }

  private getBarsUserData(code: string): Observable<any> {
    return this.apiAuthService.getUserDataMurmansk(code).pipe(
      take(1),
      switchMap(userData => {
        this.userData = userData;
        return this.handleGetUserDataResponse(this.userData);
      }),
      catchError(err => {
        this.utilsService.openSnackBar(`Серверная ошибка: ${err.error.message}`, 'error');
        return throwError(err);
      }),
    );
  }

  private handleGetUserDataResponse(userData: IUserMurmanskInterface): Observable<any> {
    if (userData) {
      let currentObservable$: Observable<any> = of(null);

      switch (userData.status) {
        case 'Success':
          {
            this.murmanskUser = loginCasesSP.LOGIN;
            currentObservable$ = this.doSchoolPortalLogin(userData);
          }
          break;
        case 'User does not exist':
          {
            // можно регистрировать пользователя
            this.handleRegisterResponse(userData.role);
          }
          break;
        case 'Different registration type':
          {
            this.utilsService.openSnackBar(
              'Для вашего пользователя недоступен вход через школьный портал. Попробуйте другой вариант входа в систему',
              'error',
            );
          }
          break;
        case 'User with current email already exists':
          {
            this.utilsService.openSnackBar('В системе уже есть другой пользователь с такой электронной почтой', 'error');
          }
          break;
        case 'Failed':
          {
            switch (userData.comment) {
              case "Can't get information about user":
                {
                  this.utilsService.openSnackBar('При входе через школьный портал возникла ошибка', 'error');
                }
                break;
              case "Can't find school":
                {
                  this.utilsService.openSnackBar('Вашей школы нет в системе', 'error');
                }
                break;
              case "Can't find city":
                {
                  this.utilsService.openSnackBar('Вашего города нет в системе', 'error');
                }
                break;

              default:
                this.utilsService.openSnackBar(`Ошибка регистрации, комментарий: ${userData.comment}`, 'error');
            }
          }
          break;
      }
      return currentObservable$;
    } else {
      return of(null);
    }
  }

  private handleRegisterResponse(role: string) {
    if (role === 'pupil' || role === 'parent') {
      this.murmanskUser = loginCasesSP.REGISTER;
    } else {
      this.murmanskUser = loginCasesSP.LOGIN;
      this.utilsService.openSnackBar('Вход через школьный портал доступен только для родителей и учеников', 'error');
    }
  }

  public doSchoolPortalLogin(SPUserData: ISchoolPortalUserData): Observable<any> {
    return this.apiAuthService.loginMurmansk(SPUserData.userId, SPUserData.email).pipe(
      switchMap(loginResult => {
        if (!loginResult || !loginResult.succeeded) {
          this.murmanskUser = loginCasesSP.LOGIN;
          this.utilsService.openSnackBar(
            '👎 Вход в систему заблокирован из-за смены пароля. Обратитесь в службу поддержки.',
            'error',
            15000,
          );
          return of(null);
        } else {
          this.userStorageService.setUserId = loginResult.userId;
          this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
          this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
          this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
          this.webStorageService.set(StorageKeys.IsAuthorized, true);
          return this.loginService.getAccess(loginResult.userId).pipe(
            tap((accessResponse: any) => {
              this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
              this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);

              this.setUserInfoInLS();

                  switch (loginResult.role) {
                    case 'parent': {
                      this.router.navigate(['/parent']);
                      return of(null);
                    }
                  }

                  return this.apiUsersService.getCurrentStepPupils().pipe(
                    switchMap(welcomeStep => {
                      this.webStorageService.set(StorageKeys.WelcomeStep, welcomeStep);
                      return this.b2gSaasService.getLastSession(loginResult.userId).pipe(
                        tap((data: any) => {
                          const sessionId = data.status == 'Success' || 'Uncompleted test' ? data.sessionId : undefined;
                          if (sessionId) {
                            this.webStorageService.set(StorageKeys.TestSessionId, sessionId);
                            this.webStorageService.set(StorageKeys.SessionStatus, data.status);
                            // если требуется обновить профиль, перекидываем сначала на страницу обновления
                            // SPUserData.needToUpdate ? (this.murmanskUser = loginCasesSP.UPDATE) : this.navigateToPupilLk();
                          } else if (data.status == 'Untested user') {
                            SPUserData.needToUpdate
                              ? (this.murmanskUser = loginCasesSP.UPDATE)
                              : this.router.navigate(['/test-na-professiyu']);
                          }
                        }),
                      );
                    }),
                  );
            }),
          );
        }
      }),
    );
  }

  public setUserInfoInLS(): void {
    const ui: IUserInfo = this.userDataHandlerService.getUserData().getValue();

    if (!ui) {
      throw new Error('User info is not defined');
    }

    this.webStorageService.set(StorageKeys.ImagePath, ui.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, ui.firstName);
    this.webStorageService.set(StorageKeys.LastName, ui.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, ui.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, ui.gender);
    this.webStorageService.set(StorageKeys.CompanyId, ui.companyId);
    this.webStorageService.set(StorageKeys.Position, ui.position);
    this.webStorageService.set(StorageKeys.RegionId, ui.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, ui.municipalityId);
    this.webStorageService.set(StorageKeys.City, ui.city);
  }

  public schoolPortalReg(): void {
    this.apiAuthService
      .getAuthUrlMurmansk()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(barsRout => {
        location.href = barsRout;
      });
  }
}
