import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import {
  ApiFavoritesService,
  ApiInternshipsService,
  ApiSchoolsService,
  ApiUsersService,
  B2gSaasService,
  EmptyGuid,
  FavoritesDataHandlerService,
  ISchool,
  IUserInfo,
  ProductTypes,
  Stands,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AGE_DATA, COMPLEXITYS, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { IApplyInternship } from 'app/shared/interfaces/iapplyinternship.interface';

@Component({
  selector: 'prf-internship-details',
  templateUrl: './internship-details.component.html',
  styleUrls: ['./internship-details.component.scss'],
})
export class InternshipDetailsComponent implements OnInit {
  internship: any;
  types: any = COURSES_TYPES;

  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherInternships: any = [];
  similarInternships: any = [];
  favoritesInternships: any;
  internshipEnrolls: any;
  startDate: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  userData: IUserInfo;
  adminLevel: string = '';
  color: string;
  createDate: string;
  complexity: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  testCompleted: boolean = false;
  applyInternship: IApplyInternship = {
    userId: '',
    internship: '',
    regionId: '',
    municnipalityId: '',
    schoolId: '',
    schoolClass: '',
  };
  school: ISchool;
  userClass: any;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private apiUsersService: ApiUsersService,
    private apiInternshipsService: ApiInternshipsService,
    private apiSchoolsService: ApiSchoolsService,
    private userDataHandlerService: UserDataHandlerService,
    private route: ActivatedRoute,
    private webStorageService: WebStorageService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private apiFavoritesService: ApiFavoritesService,
    private router: Router,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Стажировка' });
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.userData = this.userDataHandlerService.getUserData().getValue();
    if (this.userRole && this.userRole.includes('admin')) {
      this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    }
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    const sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.testCompleted = sessionStatus === 'Success';
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentInternship();
        }),
      )
      .subscribe();
  }

  loadCurrentInternship(): Observable<any> {
    return this.apiInternshipsService.getInternshipByHrid(this.hrid).pipe(
      switchMap(data => {
        this.internship = data;
        const complexity = COMPLEXITYS.find(c => c.value === this.internship.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.startDate = DateHelper.toDayJs(this.internship.startDate).format('D MMMM YYYY');

        let classEnrollObservable$: Observable<any> = of(null);
        if (this.userRole === 'pupil') {
          classEnrollObservable$ = this.loadFavorites().pipe(
            switchMap(() =>
              this.apiUsersService.getClassEnrollPupils(this.userData.userId, this.internship.id).pipe(
                tap(classEnroll => {
                  this.isEnrolled = classEnroll && classEnroll.isDeleted === false;
                }),
              ),
            ),
          );
        }

        return forkJoin([this.loadOtherCourses(), classEnrollObservable$]).pipe(
          tap(r => {
            this.dataFetched = true;
            this.overlayService.hide();
          }),
        );
      }),
    );
  }

  loadFavorites(): Observable<any> {
    return of(() => {
      this.favoritesInternships = this.favoritesDataHandlerService.getFavoriteClasses().getValue();
      if (this.favoritesInternships) {
        const productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
        this.isFavorite = productIds.length > 0;
      }
    });
  }

  addToGoals() {
    forkJoin([this.b2gSaasService.getSchool(), this.apiUsersService.getSchoolClassPupils()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([school, schcl]) => {
        this.school = school;
        this.userClass = schcl[0];
        this.apiUsersService.sendApplyForInternshipPupils(
          this.userData.userId,
          this.internship.name,
          this.internship.regionId,
          this.internship.municnipalityId,
          this.webStorageService.get(StorageKeys.SchoolId),
          this.userClass.className,
        );
        this.goToInternship();
      });
  }

  archive(internship) {
    internship.isArchived = true;
    this.apiInternshipsService
      .changeInternshipsDO(internship.id, internship)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.utilsService.openSnackBar('👌 Стажировка добавлена в архив', 'success');
        return setTimeout(_ => {
          this.router.navigate(['/admin/internships']);
        }, 2000);
      });
  }

  loadOtherCourses(): Observable<any> {
    if (this.internship.institutionId && this.internship.institutionId !== EmptyGuid) {
      const filters = {
        municipalityParams: this.userData.municipalityId,
        institutionId: this.internship.institutionId,
        from: 0,
        size: 4,
        stand: Stands.Talent,
      };
      const intershipsFilters = {
        internships: this.internship.internships,
        municipalityId: this.userData.municipalityId,
        from: 0,
        size: 13,
      };
      return forkJoin([
        this.apiSchoolsService.getElasticFilteredClasses(filters),
        this.apiInternshipsService.getFilteredInternships(intershipsFilters),
      ]).pipe(
        tap(([otherInternships, similarInternships]) => {
          this.otherInternships = otherInternships.filter(d => d.id !== this.internship.id);
          this.similarInternships = similarInternships.filter(d => d.id !== this.internship.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  addToFavorite() {
    this.apiFavoritesService
      .addToFavorites(this.internship.id, 'Internship', this.userData.userId)
      .pipe(
        tap(() => this.favoritesDataHandlerService.needsUpdate.next(ProductTypes.Class)),
        switchMap(() => this.loadFavorites()),
      )
      .subscribe();
  }

  removeFavorite() {
    const filteredFavorites = this.favoritesInternships.filter(el => el.productId === this.internship.id);
    const internshipFavoriteIds = filteredFavorites.map(el => el.id);
    if (internshipFavoriteIds) {
      internshipFavoriteIds.forEach(favoriteId => this.apiFavoritesService.deleteFromFavorites(favoriteId));
      this.isFavorite = false;
    }
  }

  goToInternship() {
    if (!this.isEnrolled) {
      this.apiUsersService
        .addClassEnrollPupils(this.internship.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => (this.isEnrolled = true));
    }
  }

  cancelClassEnroll() {
    if (this.isEnrolled) {
      this.apiUsersService
        .removeClassEnrollPupils(this.internship.id, this.userData.userId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => (this.isEnrolled = false));
    }
  }

  getAges() {
    if (this.internship.maxAgeAudience != 100 && this.internship.minAgeAudience != 0) {
      return 'от ' + this.internship.minAgeAudience + ' до ' + this.internship.maxAgeAudience + ' лет';
    } else if (this.internship.maxAgeAudience == 100) {
      return this.internship.minAgeAudience + '+ лет';
    } else if (this.internship.minAgeAudience == 0) {
      return 'до ' + this.internship.maxAgeAudience + ' лет';
    }
  }

  setTitles() {
    const path = this.router.routerState.snapshot.url;
    if (path.includes('/internships/')) {
      this.title = 'Стажировки';
      this.mapHeader = 'Стажировки на карте';
    }
  }

  backButtonUrl() {
    switch (this.userRole) {
      case 'admin':
        return '/admin/internships';
      case 'schooladmin':
        return '/schooladmin/internships';
      case 'adminDO':
        return '/adminDO/internships';
      default:
        return '/internships';
    }
  }

  deleteInternship(internshipID) {
    this.apiInternshipsService
      .removeCurrentInternship(internshipID)
      .pipe(take(1))
      .subscribe(r => {
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/admin/internships']);
      });
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'teacher':
      case 'director':
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
