import { inject, Injectable } from '@angular/core';
import { HttpService } from '@profilum-api-services/http/http.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BffProjectsSaasUrls } from './bff-projects-saas.collections';
import {
  ICompletedSessionStatus,
  IGetAnonymousSessionDataResponse,
  ILastSessionsStatus,
} from './bff-project-saas.interfaces';
import { B2gSaasUrls } from '@profilum-api-services/b2g-saas/b2g-saas.collections';
import {
  IAddUserRequest,
  IAddUserResponse,
  IGetUserInfoResponse,
} from '@profilum-api-services/b2g-saas/b2g-saas.interface';

@Injectable({
  providedIn: 'root',
})
export class BffProjectSaasService {
  private readonly httpService: HttpService = inject(HttpService);

  public addUser(userData: IAddUserRequest): Observable<IAddUserResponse> {
    return this.httpService.post<IAddUserResponse>(B2gSaasUrls.AddUser, userData).pipe(take(1));
  }

  public getUserInfo(): Observable<IGetUserInfoResponse> {
    return this.httpService.get<IGetUserInfoResponse>(B2gSaasUrls.GetUserInfo).pipe(take(1));
  }

  public getLastSession(userId: string): Observable<ILastSessionsStatus> {
    return this.httpService.get<ILastSessionsStatus>(`${BffProjectsSaasUrls.GetLastSession}?userId=` + userId);
  }

  public getCompletedSession(userId: string, testId: string, variantId: string): Observable<ICompletedSessionStatus> {
    return this.httpService.get<ICompletedSessionStatus>(`${BffProjectsSaasUrls.GetCompletedSession}`, {
      userId,
      sreeningTestId: testId,
      variantId,
    });
  }

  public getAnonymousSessionData(sessionId: string): Observable<IGetAnonymousSessionDataResponse> {
    return this.httpService.get<IGetAnonymousSessionDataResponse>(`${BffProjectsSaasUrls.GetAnonymousSessionData}`, { sessionId });
  }
}
