import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiProgramsService,
  IProfession,
  ProfessionEducationLevel,
  ProfessionsService,
  RegionIdsForNewPrograms,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems
} from '@profilum-library';

@Component({
  selector: 'prf-mobile-steps-trajectory',
  templateUrl: './mobile-steps-trajectory.component.html',
  styleUrls: ['./mobile-steps-trajectory.component.scss'],
})
export class MobileStepsTrajectoryComponent {
  @Input() hideButton: boolean = false;
  @Input() showMoreAbout: boolean = false;
  @Output() public showSpoVoPrograms: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set profession(profession: IProfession) {
    this._profession = profession;
    this.currentStep = 'averageSchool';

    if (this._profession.companies) {
      this._profession.companies.filter(x => (x.url = x.url.trim()));
    }
    const specialitiesCodes = profession.specialities.map(speciality => speciality.code);
    const regionId: string = this.userDataHandlerService.getUserData().getValue()?.regionId;

    const regionWithNewPrograms: boolean = RegionIdsForNewPrograms.includes(regionId);
    if (regionWithNewPrograms) {
      this.apiProgramsService.getProgramsSpoVpoBySpecialityCodes({
        specialityCodes: specialitiesCodes,
        regionId: regionId
      })
        .subscribe(programs => {
            programs.programsSpoVpo.forEach(program => {
              if (this.programsBySpecialityName[program.specialityName]) {
                this.programsBySpecialityName[program.specialityName].push(program)
              } else
                this.programsBySpecialityName[program.specialityName] = [program]
            })
          }
        );
    }

    this.specialsCollage = profession.specialities.filter(speciality => speciality.educationLevel === ProfessionEducationLevel.Collage);
    this.specialsUniversity = profession.specialities.filter(speciality => speciality.educationLevel === ProfessionEducationLevel.University);

    this.setSchemeType();
  }

  get profession(): IProfession {
    return this._profession;
  }

  public isOpen: boolean = true;
  public programsBySpecialityName: any = [];
  public currentStep: string = 'averageSchool';
  public specialsCollage: Array<any> = [];
  public specialsUniversity: Array<any> = [];
  public schemeType: string = 'twoBranches'; // [twoBranches, withoutCollege, withoutUniversity]

  userRole: string;
  private _profession: IProfession;

  constructor(
    private professionService: ProfessionsService,
    private router: Router,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
    private apiProgramsService: ApiProgramsService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  public showProfession() {
    this.professionService.setCurrentProfession(this.profession);
    this.router.navigate(['/professions', this.profession.hrid]);
  }

  public goToCourses(course: any) {
    this.yandexMetricsService.reachGoal(YmItems.S_ProfessionsCard_Courses);
    this.yandexMetricsService.reachGoal(YmItems.T_Professions_Courses);
    this.webStorageService.set(StorageKeys.Course, course);
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'teacher':
      case 'tutor':
        this.router.navigate(['/courses']);
        break;
      default:
        this.router.navigate([`${this.userRole}/courses`]);
        break;
    }
  }

  public showPrograms(special): void {
    if (this.programsBySpecialityName[special.name]) {
      this.showSpoVoPrograms.emit({special: special, programs: this.programsBySpecialityName[special.name]});
    }
  }

  private setSchemeType() {
    if (this._profession && this._profession.education && this._profession.education.length) {
      if (
        this._profession.education.some(e => e.level === 2) &&
        this._profession.education.some(e => e.level === 3 && e.perentage !== 100)
      ) {
        this.schemeType = 'twoBranches';
        return;
      }

      if (this._profession.education.some(e => e.level === 3 && e.perentage === 100)) {
        this.schemeType = 'withoutCollege';
        return;
      }

      if (!this._profession.education.some(e => e.level === 3)) {
        this.schemeType = 'withoutUniversity';
        return;
      }
    }
  }
}
