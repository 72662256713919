import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  ApiCoursesMaterialsService,
  ApiProfessionsService,
  ApiUsersService,
  B2gSaasService,
  IExtendedPupil,
  IFavoriteProfessionInterface,
  ISchool,
  ISchoolClass,
  UserDataHandlerService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { ExtendedPupil, IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ITeacher } from '../../../../shared/interfaces/iteacher.interface';
import {
  InvitationInstructionService,
} from '../../../../ui-kit/services/invitation-instruction/invitation-instruction.service';

@Component({
  selector: 'prf-director-class',
  templateUrl: './director-class.component.html',
  styleUrls: ['./director-class.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DirectorClassComponent extends UnsubscribeComponent implements OnInit {
  @Input() schoolClassIdToOpen: string = '';
  public pupilId: string = '';
  public schoolClass: ISchoolClass;
  public teacher: ITeacher;
  public pupils: IExtendedPupil[] = [];
  public favoriteProfessions: any = [];
  public dataFetched: boolean = false;
  public showEditPupilsComponent: boolean = false;
  public showEditClassComponent: boolean = false;
  public isDiagnosticClass: boolean = false;
  public title: string = '';
  public schoolTitle: string = '';
  public school: ISchool;
  public messagePopUpShow: boolean = false;
  private selectedPupil: IExtendedPupil;
  public resetPasswordPopup: boolean = false;
  public notEnteredPupil: IPupilsInClass;
  private userProfile: any;
  private teacherId: string;

  constructor(
    private apiUsersService: ApiUsersService,
    private b2gSaasService: B2gSaasService,
    private overlayService: OverlayBusyService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private userDataHandlerService: UserDataHandlerService,
    private apiProfessionsService: ApiProfessionsService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private pageHeaderService: PageHeaderService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private invitationInstructionService: InvitationInstructionService,
  ) {
    super();
    matIconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/close.svg'));

    this.pupilId = this.router.getCurrentNavigation()?.extras.state?.userId;
  }

  ngOnInit(): void {
    this.overlayService.show();
    this.b2gSaasService
      .getSchool()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(school => {
        this.school = school;
        this.schoolTitle = this.school.number;
      });

    this.getSchoolClass()
      .pipe(
        switchMap(classInfo => {
          if (classInfo) {
            return this.getDashboardData();
          } else {
            return of(null);
          }
        }),
        switchMap(() => {
          if (this.schoolClass?.id) {
            return this.apiCoursesMaterialsService.getCourseByClass(this.schoolClass?.id);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((course: any) => {
        if (course) {
          this.isDiagnosticClass = !course?.length;
        }
        this.dataFetched = true;
        this.overlayService.hide();
        this.timer = setTimeout(() => this.scrollToLastSelected(), 0);
      });

    this.pageHeaderService.parentMessage$.pipe(takeUntil(this.unsubscribe)).subscribe(r => {
      this.showMessagePopUp();
    });
  }

  private scrollToLastSelected(): void {
    if (this.pupilId) {
      const lastSelected = document.querySelector('.last-selected');
      lastSelected.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public getSchoolClass(): Observable<any> {
    const classID = this.route.snapshot.paramMap.get('classId');
    return this.apiUsersService.getSchoolClassDirectors(classID).pipe(
      tap(
        schoolClass => {
          if (schoolClass === 'CANT_FIND') {
            this.router.navigate(['/director/classes']);
            return this.utilsService.openSnackBar('👎 Данный класс не был найден', 'error');
          } else {
            this.schoolClass = {
              className: schoolClass.className,
              id: schoolClass.id,
              letter: schoolClass.letter,
              number: schoolClass.number,
              pupilsCount: schoolClass.pupilsCount,
              schoolId: schoolClass.schoolId,
              teacherId: schoolClass.teacherId,
            };
          }
        },
        err => {
          return this.utilsService.openSnackBar(ServerErrorMessage, 'error');
        },
      ),
    );
  }

  public isTestOld(lastTestDate: Date | string): boolean {
    const dateCheck = new Date();
    const dateCompare = new Date(lastTestDate);
    dateCheck.setMonth(dateCheck.getMonth() - 3);
    return dateCompare <= dateCheck;
  }

  private getDashboardData(): Observable<any> {
    this.userProfile = this.userDataHandlerService.getUserData().getValue();

    return this.apiUsersService.getTeacherDirectors(this.schoolClass.id).pipe(
      switchMap(teacher => {
        this.teacher = teacher;
        return this.apiUsersService.getExtendedPupilsDirectors(this.schoolClass.id);
      }),
      switchMap((extendedPupils: any) => {
        return this.apiUsersService.getPupilsDirectors(this.schoolClass.id).pipe(
          switchMap(pupils => {
            // совпадающие ученики
            const pupilsBase = extendedPupils.filter(exPupil => pupils.some(p => p.userId === exPupil.userId));

            const userIds = pupilsBase.map(x => x.userId);
            const newPupils = pupils
              .filter(x => userIds.indexOf(x.userId) < 0)
              .map(user => {
                return new ExtendedPupil(user);
              });
            this.pupils = [...pupilsBase, ...newPupils];

            return this.getFavoritesProfessions(this.pupils);
          }),
        );
      }),
    );
  }

  public showMessagePopUp(): void {
    this.messagePopUpShow = true;
  }
  public closeMessagePopUp(): void {
    this.messagePopUpShow = false;
  }

  public sendMessagesAll(): void {
    this.apiUsersService
      .sendResultsMessagesDirectors()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          if (response.status === 'Success') {
            this.utilsService.openSnackBar('👌 Сообщения отправлены на почту родителям', 'success');
          } else {
            this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          }
          this.timer = setTimeout(() => (this.messagePopUpShow = false), 500);
        },
        err => {
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          this.timer = setTimeout(() => (this.messagePopUpShow = false), 500);
        },
      );
  }

  public routingMain(): void {
    this.router.navigate(['/director']);
  }

  public showElement(event: any): void {
    event.target.nextSibling.style.display = 'block';
  }

  public hideElement(event: any): void {
    event.target.nextSibling.style.display = 'none';
  }

  public navigateSelectedPupil(pupil: any): void {
    this.selectedPupil = pupil;
    this.router.navigate(['director/pupil'], {
      queryParams: {
        classId: this.selectedPupil.schoolClassId,
        userId: this.selectedPupil.userId,
      },
    });
  }

  private getFavoritesProfessions(pupils: any): Observable<any> {
    const studentsWithProfessions = pupils.filter(el => el.favoriteProfessions.length > 0);
    let professionsIds = studentsWithProfessions.map(el => el.favoriteProfessions.map(e => e.productId || e.id));
    professionsIds = professionsIds.reduce((a, b) => a.concat(b), []);
    const unique = [...new Set(professionsIds)];

    return this.apiProfessionsService.getElasticProfessionsByFilters({ ids: unique }).pipe(
      tap(favoriteProfessions => {
        this.favoriteProfessions = favoriteProfessions;
        this.parseStudentsProfessions(this.pupils);
      }),
    );
  }

  private parseStudentsProfessions(pupils: any): void {
    pupils.forEach((student, i) => {
      if (student.favoriteProfessions.length > 0) {
        const professionsIds = student.favoriteProfessions.map(e => e.productId || e.id);
        const unique = [...new Set(professionsIds)];
        const professions: IFavoriteProfessionInterface[] = [];
        unique.forEach(id => {
          const profession = this.favoriteProfessions.find(el => el.id === id);
          profession ? professions.push(profession) : null;
        });
        this.pupils[i].favoriteProfessions = professions;
      }
    });
  }

  public getImageUrl(user: any): string {
    return user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }

  public getImagePath(job: any): string | null {
    if (job) {
      if (job.mainImagePath) {
        job.mainImagePath = job.mainImagePath.replace('/content/images/professions/default_icons/', './profilum-assets/images/profession/');
      } else {
        job.mainImagePath = './profilum-assets/images/illustrations/petard.png';
      }
      return job.mainImagePath;
    }
    return null;
  }

  public showResetPasswordPopup(pupil?: IExtendedPupil) {
    if (pupil) {
      this.notEnteredPupil = this.invitationInstructionService.createInvitationCardData(pupil);
    }
    this.resetPasswordPopup = !this.resetPasswordPopup;
  }
}
