import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAuthService,
  ApiCoursesMaterialsService,
  ApiUsersService,
  AppSettingsService,
  AuthHandlerService,
  B2gSaasService,
  ISchoolPortalUserData,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

import { LoginBase } from 'app/shared/auth-classes/login.base';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

export enum loginCasesSP {
  LOGIN,
  REGISTER,
  UPDATE,
}

@Component({
  selector: 'prf-login-mosobl',
  templateUrl: './login-mosobl.component.html',
  styleUrls: ['./login-mosobl.component.scss'],
})
export class LoginMosOblComponent extends LoginBase<B2gSaasService> implements OnInit {
  private authCode: string = null;
  public SPUserData: ISchoolPortalUserData = {
    userId: null,
    externalAppUserId: null,
    email: null,
    firstName: null,
    middleName: null,
    lastName: null,
    role: null,
    schoolId: null,
    birthday: null,
    gender: null,
    city: null,
    tag: null,
    status: null,
    comment: null,
  };

  public loginCasesSP = loginCasesSP;
  public schoolPortalUser: any = loginCasesSP.LOGIN;

  constructor(
    meta: Meta,
    translateService: TranslateService,
    overlayService: OverlayBusyService,
    loginService: B2gSaasService,
    b2gSaasService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    appSettings: AppSettingsService,
    @Inject(DOCUMENT) document: Document,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private apiAuthService: ApiAuthService,
    private userDataHandlerService: UserDataHandlerService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    apiCoursesMaterialsService: ApiCoursesMaterialsService,
    apiUsersService: ApiUsersService,
    authHandlerService: AuthHandlerService,
  ) {
    super(
      meta,
      translateService,
      overlayService,
      loginService,
      b2gSaasService,
      router,
      fb,
      document,
      webStorageService,
      userStorageService,
      appSettings,
      apiCoursesMaterialsService,
      apiUsersService,
      authHandlerService,
    );

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.authCode = params['code'];
    });
  }

  ngOnInit(): void {
    if (this.authCode) {
      // запрос данных пользователя, зарегистрировавшегося на schoolPortal
      this.getSchoolPortalUserData(this.authCode).pipe(takeUntil(this.unsubscribe)).subscribe();
    }
    super.ngOnInit();
  }

  getSchoolPortalUserData(code: string): Observable<any> {
    return this.apiAuthService.getUserDataSP(code).pipe(
      take(1),
      switchMap(SPUserData => {
        this.SPUserData = SPUserData;
        return this.handleGetUserDataResponse(this.SPUserData);
      }),
      catchError(err => {
        this.utilsService.openSnackBar(`Серверная ошибка: ${err.error.message}`, 'error');
        return throwError(err);
      }),
    );
  }

  private handleGetUserDataResponse(SPUserData: ISchoolPortalUserData): Observable<any> {
    if (SPUserData) {
      let currentObservable$: Observable<any> = of(null);

      switch (SPUserData.status) {
        case 'Success':
          {
            // можно логинить пользователя
            this.schoolPortalUser = loginCasesSP.LOGIN;
            currentObservable$ = this.doSchoolPortalLogin(SPUserData);
          }
          break;
        case 'User does not exist':
          {
            // можно регистрировать пользователя
            this.handleRegisterResponse(SPUserData.role);
          }
          break;
        case 'Different registration type':
          {
            this.utilsService.openSnackBar(
              'Для вашего пользователя недоступен вход через школьный портал. Попробуйте другой вариант входа в систему',
              'error',
            );
          }
          break;
        case 'User with current email already exists':
          {
            this.utilsService.openSnackBar('В системе уже есть другой пользователь с такой электронной почтой', 'error');
          }
          break;
        case 'Failed':
          {
            switch (SPUserData.comment) {
              case "Can't get information about user Dnevnik":
                {
                  this.utilsService.openSnackBar('При входе через школьный портал возникла ошибка', 'error');
                }
                break;
              case "Can't find school":
                {
                  this.utilsService.openSnackBar('Вашей школы нет в системе', 'error');
                }
                break;
              case "Can't find city":
                {
                  this.utilsService.openSnackBar('Вашего города нет в системе', 'error');
                }
                break;

              default:
                this.utilsService.openSnackBar(`Ошибка регистрации, комментарий: ${SPUserData.comment}`, 'error');
            }
          }
          break;
      }
      return currentObservable$;
    } else {
      return of(null);
    }
  }

  private handleRegisterResponse(role: string) {
    if (role === 'pupil' || role === 'parent') {
      this.schoolPortalUser = loginCasesSP.REGISTER;
    } else {
      this.schoolPortalUser = loginCasesSP.LOGIN;
      this.utilsService.openSnackBar('Вход через школьный портал доступен только для родителей и учеников', 'error');
    }
  }

  public doSchoolPortalLogin(SPUserData: ISchoolPortalUserData): Observable<any> {
    return this.apiAuthService.loginSP(SPUserData.userId, SPUserData.email).pipe(
      switchMap((loginResult: any) => {
        if (!loginResult || loginResult.succeeded === false) {
          this.schoolPortalUser = loginCasesSP.LOGIN;
          this.utilsService.openSnackBar(
            '👎 Вход в систему заблокирован из-за смены пароля. Обратитесь в службу поддержки.',
            'error',
            15000,
          );
          return of(null);
        } else {
          this.userStorageService.setUserId = loginResult.userId;
          this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
          this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
          this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
          this.webStorageService.set(StorageKeys.IsAuthorized, true);
          return this.loginService.getAccess(loginResult.userId).pipe(
            tap((accessResponse: any) => {
              this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
              this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);

              this.setUserInfoInLS();

                  switch (loginResult.role) {
                    case 'parent': {
                      this.router.navigate(['/parent']);
                      return of(null);
                    }
                  }

                  return this.apiUsersService.getCurrentStepPupils().pipe(
                    switchMap(welcomeStep => {
                      this.webStorageService.set(StorageKeys.WelcomeStep, welcomeStep);
                      return this.b2gSaasService.getLastSession(loginResult.userId).pipe(
                        tap((data: any) => {
                          const sessionId = data.status == 'Success' || 'Uncompleted test' ? data.sessionId : undefined;
                          if (sessionId) {
                            this.webStorageService.set(StorageKeys.TestSessionId, sessionId);
                            this.webStorageService.set(StorageKeys.SessionStatus, data.status);
                            // если требуется обновить профиль, перекидываем сначала на страницу обновления
                            // SPUserData.needToUpdate ? (this.schoolPortalUser = loginCasesSP.UPDATE) : this.navigateToPupilLk();
                          } else if (data.status == 'Untested user') {
                            SPUserData.needToUpdate
                              ? (this.schoolPortalUser = loginCasesSP.UPDATE)
                              : this.router.navigate(['/test-na-professiyu']);
                          }
                        }),
                      );
                    }),
                  );
            }),
          );
        }
      }),
    );
  }

  public setUserInfoInLS(): void {
    const ui: IUserInfo = this.userDataHandlerService.getUserData().getValue();

    if (!ui) {
      throw new Error('User info is not defined');
    }

    this.webStorageService.set(StorageKeys.ImagePath, ui.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, ui.firstName);
    this.webStorageService.set(StorageKeys.LastName, ui.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, ui.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, ui.gender);
    this.webStorageService.set(StorageKeys.CompanyId, ui.companyId);
    this.webStorageService.set(StorageKeys.Position, ui.position);
    this.webStorageService.set(StorageKeys.RegionId, ui.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, ui.municipalityId);
    this.webStorageService.set(StorageKeys.City, ui.city);
  }

  schoolPortalReg() {
    this.apiAuthService
      .getAuthUrlSP()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(schoolPortalRoute => {
        if (schoolPortalRoute) {
          location.href = schoolPortalRoute.url;
          return;
        }
      });
  }

  public mesPortalReg(): void {
    location.href = AppSettingsService.settings.mesRedirectUrl;
  }
}
