import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  ApiCoursesMaterialsService,
  ApiUsersService,
  AppSettingsService,
  IExtendedPupil,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { IPupilsInClass } from 'app/shared/interfaces/iextendedpupil.interface';
import { MOSRED_ID } from 'app/shared/interfaces/regions-id.interface';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import { IChangePhoneInfo } from 'app/shared/common-components/pupils-table/pupils-table.component';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';

export interface ICurrentCLassData {
  classId: string;
  parentLink: string;
  className: string;
}

@Component({
  selector: 'prf-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss'],
})
export class TeacherDashboardComponent extends UnsubscribeComponent implements OnInit {
  public pupils: IExtendedPupil[] = [];
  public schoolClass: ICurrentCLassData = null;
  public dataFetched: boolean = false;
  public isMOStend: boolean = false;
  public teacherHasNoClasses$: Observable<boolean>;
  public resetPasswordPopup: boolean = false;
  public changePupilPhonePopup: boolean = false;
  public successChanges: boolean = false;
  public pupil: IPupilsInClass;
  public changePhoneInfo: IChangePhoneInfo;
  public undoEditing: boolean = false;
  public continueEditing: boolean = false;
  public isDiagnosticClass: boolean = false;
  public dictionary: DictionaryType;
  public isKruzhkiVariant: boolean = false;
  public isPupilsInvitationVisible: boolean = true;
  private isUzbekistanPilotRegion: boolean = false;
  public userRestriction: boolean = false;
  private mosregId: string = MOSRED_ID;

  constructor(
    private apiUsersService: ApiUsersService,
    private overlayService: OverlayBusyService,
    private rootScopeService: RootScopeService,
    private ramStorageService: RamStorageService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private userActionsService: UserActionsService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.dictionary = rootScopeService.getDictionary();
    this.userRestriction = userDataHandlerService.isUserRestricted();
    this.isUzbekistanPilotRegion = AppSettingsService.settings.pilotUzbekistanRegionsId.includes(
      this.userDataHandlerService.getUserData().getValue().regionId,
    );
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Pupils);
    this.teacherHasNoClasses$ = ramStorageService.get(StorageKeys.TeacherHasNoClasses).pipe(
      tap((teacherHasNoClasses: boolean) => {
        if (teacherHasNoClasses) {
          this.overlayService.hide();
        }
      }),
    );
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.isMOStend = this.mosregId === this.webStorageService.get(StorageKeys.RegionId);
    this.isPupilsInvitationVisible = !this.isMOStend && !this.isKruzhkiVariant && !this.isUzbekistanPilotRegion;

    this.rootScopeService.currentCLass
      .pipe(
        filter(v => v !== null),
        switchMap(selectedClass => {
          this.overlayService.show();
          const classInfo = selectedClass.schoolClass;
          this.schoolClass = {
            classId: classInfo.id,
            parentLink: classInfo.shortLink ? classInfo.shortLink : classInfo.parentRegistrationLink,
            className: classInfo.className,
          };

          this.webStorageService.set(StorageKeys.SchoolId, classInfo.schoolId);

          return combineLatest([
            this.loadPupils(this.schoolClass.classId),
            this.apiCoursesMaterialsService.getCourseByClass(this.schoolClass.classId),
          ]);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        ([pupils, el]: any) => {
          this.dataFetched = true;
          this.overlayService.hide();
          this.isDiagnosticClass = !el.length;
        },
        () => {
          this.dataFetched = true;
          this.overlayService.hide();
        },
      );

    this.userActionsService.setInitializationTime([YmItems.T_Class_InvitationInEmptyClass, YmItems.T_Class_invitationInNotEmptyClass]);
  }

  private loadPupils(classId: string): Observable<any> {
    return this.apiUsersService.getExtendedPupilsTeachers(classId).pipe(
      switchMap(extendedPupils => {
        return this.apiUsersService.getPupilsTeachers(classId).pipe(
          tap(pupils => {
            this.pupils = pupils;

            if (extendedPupils.length) {
              const pupilsExtended = extendedPupils.reduce((prev, current) => {
                prev[current.userId] = current;
                return prev;
              }, {});

              this.pupils = this.pupils.map(el => {
                return { ...pupilsExtended[el.userId], ...el };
              });
            }
          }),
        );
      }),
    );
  }

  public changeResetPasswordPopup(event?: IPupilsInClass): void {
    if (event) {
      this.pupil = event;
    }
    this.resetPasswordPopup = !this.resetPasswordPopup;
  }

  public toggleChangePhonePopup(event?: IChangePhoneInfo): void {
    if (event) {
      this.pupil = event.pupil;
      this.changePhoneInfo = event;
    }
    this.changePupilPhonePopup = true;
  }

  public closeChangePhonePopup(event?: boolean): void {
    if (!event) {
      this.undoEditing = true;
    } else {
      this.continueEditing = true;
    }

    this.changePupilPhonePopup = false;
  }

  public closeChangePhonePopupWithSave(event?: boolean): void {
    if (event) {
      this.successChanges = true;
    }
    this.changePupilPhonePopup = false;
  }
}
