import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { COURSES_TYPES } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { MetroColors } from 'app/pages/catalog/courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { take, takeUntil, tap } from 'rxjs/operators';
import {
  ApiFavoritesService,
  CalculationService,
  FavoritesDataHandlerService,
  IClass,
  IFavoriteProfessionInterface,
  IUserProfileInfo,
  ProductTypes,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() course: IClass;
  @Input() favouritesAccess: boolean = true;
  @Input() directCourseRouting: boolean = false;
  public types: typeof COURSES_TYPES = COURSES_TYPES;
  public currentType: any = { color: '' };
  public favouriteId: string = null;
  public isCourse: boolean;
  private favoritesCourses: IFavoriteProfessionInterface[];
  public userId: string;
  public metroColors: MetroColors = new MetroColors();
  private isToggleFavorite: boolean = false;
  public userRole: string = '';
  public courseStartDate: string;
  public heartIconClass: string = 'event-heart';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  constructor(
    private calculationService: CalculationService,
    private router: Router,
    private apiFavoritesService: ApiFavoritesService,
    private yandexMetricsService: YandexMetricsService,
    private webStorageService: WebStorageService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const userData: IUserProfileInfo = this.userDataHandlerService.getUserInfo();
    this.userRole = userData ? userData.role : this.webStorageService.get(StorageKeys.UserRole);
    this.userId = userData ? userData.userId : this.webStorageService.get(StorageKeys.UserId);

    this.isCourse = this.course.classesFormat === 'ShortCourse' || this.course.classesFormat === 'LongCourse';
    this.courseStartDate = DateHelper.toDayJs(this.course.startDate).format('D MMM в hh:mm');

    this.currentType = this.getTypeCourse(this.course.classesFormat);

    this.loadFavorites();
    this.favouriteId = this.favoritesCourses.find(el => el.productId === this.course.id)?.id;
  }

  public calculateRealPrice(): number {
    return this.calculationService.calculateRealPrice(this.course);
  }

  public toggleFavorite() {
    this.isToggleFavorite = true;

    if (this.favouriteId) {
      this.removeFavorite();
      this.favouriteId = null;
    } else {
      this.addToFavorite();
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  private getTypeCourse(value: string) {
    const type = this.types.filter(el => el.value === value);
    return type[0];
  }

  private loadFavorites(): void {
    this.favoritesCourses = this.favoritesDataHandlerService.getFavoriteClasses().getValue();
  }

  public addToFavorite(): void {
    this.apiFavoritesService
      .addToFavorites(this.course.id, 'Class', this.userId)
      .pipe(
        tap(() => this.favoritesDataHandlerService.needsUpdate.next(ProductTypes.Class)),
        take(1),
      )
      .subscribe(response => {
        this.favouriteId = response.id;
        this.loadFavorites();
      });
  }

  public removeFavorite(): void {
    this.apiFavoritesService
      .deleteFromFavorites(this.favouriteId)
      .pipe(
        tap(() => this.favoritesDataHandlerService.needsUpdate.next(ProductTypes.Class)),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.favouriteId = null;
      });
  }

  public checkAction(event: any): void {
    if (!event.target.classList.contains(this.heartIconClass)) {
      if (!this.directCourseRouting) {
        this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
      } else {
        const url: string = this.course.url;
        window.open(url, '_blank');
      }
      return;
    }

    if (!this.isToggleFavorite) {
      this.yandexMetricsService.reachGoal(YmItems.T_Courses_Course);

      switch (true) {
        case this.heart && this.heart.nativeElement.contains(event.target):
          this.addToFavorite();
          break;
        case this.heartActive && this.heartActive.nativeElement.contains(event.target):
          this.removeFavorite();
          break;
        default:
          break;
      }
    } else {
      this.favoritesCourses = null;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
