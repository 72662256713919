import { Component, OnDestroy, OnInit } from '@angular/core';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ApiPlayerService, IUserInfo, UserDataHandlerService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-pupil-tests-history-page',
  templateUrl: './pupil-tests-history-page.component.html',
  styleUrls: ['./pupil-tests-history-page.component.scss'],
})
export class PupilTestsHistoryPageComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public userData: IUserInfo;
  testsResultsView: any[] = [];
  public sessionList: IHistory[];
  isCompletedTestUpToDate: boolean = false;

  private newDate = new Date();

  constructor(private apiPlayerService: ApiPlayerService, private userDataHandlerService: UserDataHandlerService) {
    super();
    this.userData = this.userDataHandlerService.getUserData().getValue();
  }

  ngOnInit() {
    this.loadHistoryTests().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  private loadHistoryTests(): Observable<any> {
    return this.apiPlayerService.getSessionsList(this.userData.userId).pipe(
      tap(r => {
        this.sessionList = r;
      }),
    );
  }

  getCompletedTest(results: any[]) {
    if (
      results.some(el => {
        //check the last completed session is newer than 6 months
        return (
          el.sessionListView.completed &&
          Date.parse(el.sessionListView.createdDate) < new Date(this.newDate).setMonth(this.newDate.getMonth() - 6)
        );
      })
    ) {
      this.isCompletedTestUpToDate = true;
    } else {
      this.isCompletedTestUpToDate = false;
    }
  }
}
