import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import {
  ApiFavoritesService,
  ApiFieldsService,
  ApiPlayerService,
  ApiProfessionsService,
  ApiProfilesService,
  B2gSaasService,
  B2gTinderService,
  B2gTinderTypes,
  CCURegionId,
  EmptyGuid,
  FavoritesDataHandlerService,
  FieldTypes,
  IData,
  IFavoriteProfessionInterface,
  IGetProfessionsResponse,
  IProfession,
  IUserInfo,
  ProfessionFilter,
  ProfessionsDataHandlerService,
  ProfessionsService,
  ResultsTestService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { LoadingHandlerService } from '../../../../ui-kit/services/loading-handler/loading-handler.service';
import { IClassesFormatsForProfessions } from 'app/shared/interfaces/iclassesformatsforprofessions.interface';
import { IProfessionsTryOnData } from 'app/shared/interfaces/iprofessionstryon.interface';
import { ApplyFilters, FavouriteEvent } from '../profession.interface';
import { ResultsService } from 'app/pages/results/results.service';
import {
  IVariant,
} from 'app/shared/common-components/discover-intro-recommendations/discover-intro-recommendations.component';

@Component({
  selector: 'prf-profession-mobile-catalog',
  templateUrl: './profession-mobile-catalog.component.html',
  styleUrls: ['./profession-mobile-catalog.component.scss'],
})
export class ProfessionMobileCatalogComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  @ViewChild('loading') public loadingTemplate: TemplateRef<any>;

  public categoryList: Array<ProfessionFilter> = [];
  public filteredCategory: Array<ProfessionFilter> = [];
  public professionsByCategory: Array<IProfession> = [];
  public professionList: Array<IProfession> = [];
  public professionListFiltered: Array<IProfession> = [];
  public loadedCategory: boolean = false;
  public loading: boolean = false;
  public userRole: string = '';
  public userInitials: string = '';
  public userInfo: IUserInfo;
  public results;
  public selectProfession: IProfession;

  public professionListFilteredView: Array<IProfession> = [];
  public recomProfessions: Array<IProfession> = [];
  public professionsFavouriteByCategory: Array<IProfession> = [];
  public currentFilterProfByCategory: Array<IProfession> = [];
  public filteredFavouriteCategory: Array<ProfessionFilter> = [];
  public currentFilterCategory: Array<ProfessionFilter> = [];
  public sortProfessionsList: Array<IProfession> = [];
  private countElement: number = 0;
  public isSearchList: boolean = false;
  public searchClear: boolean = false;
  public isFilters: boolean = false;
  public onbording: boolean = false;

  public favourites = {};
  public recommended = {};
  public recommendedCapabilities = {};
  public recommendedArray: Array<string> = [];
  public recommendedArrayCapabilities: Array<string> = [];
  public favouritesList: Array<IProfession> = [];
  public recommendedList: Array<IProfession> = [];
  public recommendedListCapabilities: Array<IProfession> = [];

  private filters: any = {};
  public showFilters: boolean = false;
  public searchList: Array<IProfession> = [];
  public searchCategoryList: Array<ProfessionFilter> = [];
  public applyFilters: ApplyFilters = {
    favourite: false,
    interests: false,
    capabilities: false,
    category: false,
  };
  public filtersState = {
    favourite: false,
    interests: false,
    capabilities: false,
    category: false,
    appliedCategories: [],
  };
  public sessionIdsInterests: string = '';
  public sessionIdsCapabilities: string = '';
  private fieldType: FieldTypes;

  public isBvb: boolean = false;
  public showBvbRecommendations: boolean = false;
  public eventProf: FavouriteEvent;
  public professionTryingOnStage: number = 0;

  private readonly countTopProfession: number = 20;
  private filteredCategoryId: string;

  constructor(
    private professionsService: ProfessionsService,
    private resultService: ResultsService,
    private b2gSaasService: B2gSaasService,
    private webStorageService: WebStorageService,
    private router: Router,
    private userActionsService: UserActionsService,
    private yandexMetricsService: YandexMetricsService,
    private userDataHandlerService: UserDataHandlerService,
    private apiPlayerService: ApiPlayerService,
    private apiProfessionsService: ApiProfessionsService,
    private apiFavoritesService: ApiFavoritesService,
    private apiFieldsService: ApiFieldsService,
    private apiProfilesService: ApiProfilesService,
    private tinderService: B2gTinderService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
    private professionsDataHandlerService: ProfessionsDataHandlerService,
    private resultsTest: ResultsTestService,
    private loadingHandlerService: LoadingHandlerService,
  ) {
    super();
    this.onbording = this.webStorageService.get(StorageKeys.Onbording);
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.isBvb = location.pathname.includes('bvb');
  }

  public ngOnInit(): void {
    this.userInfo = this.userDataHandlerService.getUserData().getValue();
    this.userInitials = (this.userInfo.firstName[0] + this.userInfo.lastName[0]).toUpperCase();
    this.applyFilters.interests = location.href.includes('interests');
    this.applyFilters.capabilities = location.href.includes('capabilities');
    const applyFilters: ApplyFilters = this.webStorageService.get(StorageKeys.ApplyProfessionFilters);
    const filteredCategory: ProfessionFilter[] = this.webStorageService.get(StorageKeys.FilteredProfessionCategory);
    this.filteredCategoryId = this.webStorageService.get(StorageKeys.FilteredProfessionCategoryId);
    this.professionTryingOnStage = this.webStorageService.get<IProfessionsTryOnData>(StorageKeys.ProfessionTryingOnData)?.nextStage ?? 0;

    if (applyFilters) {
      this.applyFilters = applyFilters;
      this.webStorageService.set(StorageKeys.ApplyProfessionFilters, null);
    }
    if (filteredCategory) {
      this.filteredCategory = filteredCategory;
      this.webStorageService.set(StorageKeys.FilteredProfessionCategory, null);
    }

    const resultsTestId = this.resultsTest.getResultsTestId().value;

    this.sessionIdsInterests = resultsTestId?.complitedInterestTestId;
    this.sessionIdsCapabilities = resultsTestId?.complitedCapabilitiesTestId;

    this.setDefaultFilters();
    this.getCategoryList();
    this.userActionsService.setInitializationTime([YmItems.S_Professions_Likes]);

    this.userActionsService.log(YmItems.S_Professions);

    this.professionsService
      .getSortingProfession()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        return (this.professionListFiltered = this.getProfessionsByOrder(this.professionList));
      });
  }

  public ngAfterViewInit(): void {
    this.loadingHandlerService.loadingModalTemplate.next(this.loadingTemplate);
  }

  private setDefaultFilters(): void {
    const isPilot: boolean = this.webStorageService.get(StorageKeys.IsPilot) == 'true';
    const regionId: string = this.userInfo.regionId;
    const regionIdValue: string = regionId && regionId != 'null' && !isPilot ? regionId : EmptyGuid;
    Object.assign(this.filters, { regionId: regionIdValue });

    this.fieldType = regionId === CCURegionId ? FieldTypes.DepCult : FieldTypes.Default;
    Object.assign(this.filters, { fieldType: this.fieldType });

    if (!isPilot) {
      const municipalityId: string = this.userInfo.municipalityId;
      const municipalityIdValue: string = municipalityId && municipalityId != 'null' ? municipalityId : null;
      Object.assign(this.filters, { municipalityId: municipalityIdValue });
    }
  }

  // данные из поиска
  public dataSearch(event: string): void {
    const data: string = event.toLowerCase();
    this.searchClear = false;
    this.searchList = [];
    this.searchCategoryList = [];
    const searchProfessions: IProfession[] = this.isFilters ? this.sortProfessionsList : this.professionListFiltered;
    const searchCategory: ProfessionFilter[] = this.isFilters ? this.currentFilterCategory : this.categoryList;
    if (data.length > 2) {
      this.isSearchList = true;
      searchProfessions.forEach((prof: IProfession) => {
        if (prof.name.toLowerCase().indexOf(data) > -1) {
          this.searchList.push(prof);
        }
      });
      searchCategory.forEach((category: ProfessionFilter) => {
        if (category.name.toLowerCase().indexOf(data) > -1) {
          category.isOpen = true;
          this.searchCategoryList.push(category);
        }
      });
    } else {
      this.isSearchList = false;
    }
  }

  public getProfessionCatalog(selectedProfessionsCount?: number, reset?: boolean): void {
    this.loading = true;
    if (reset) {
      this.professionList = [];
      this.professionListFiltered = [];
      this.professionListFilteredView = [];
      this.countElement = 0;
    }

    this.filters.isVisible = true;
    const professionList: IProfession[] = this.professionsDataHandlerService.getProfessions().value;

    if (professionList?.length) {
      this.professionList = professionList;

      this.categoryList = this.professionsDataHandlerService.getCategoryList().value;
      this.loadedCategory = true;

      this.setProfessionsByCategory();
      this.professionListFiltered = this.getProfessionsByOrder(this.professionList);
      if (this.professionListFiltered.length) {
        this.isBvb ? this.getBvbFavorite() : this.loadFavourites();
        this.getProfessionsRecommended();
      }
      this.loading = false;
    } else {
      this.apiProfessionsService
        .getElasticProfessionsByFilters(this.filters)
        .pipe(take(1), takeUntil(this.unsubscribe))
        .subscribe(professions => {
          this.professionsDataHandlerService.setProfessions(professions);
          professions.forEach(p => this.professionList.push(p));
          this.setProfessionsByCategory();
          this.professionListFiltered = this.getProfessionsByOrder(this.professionList);
          if (this.professionListFiltered.length) {
            this.isBvb ? this.getBvbFavorite() : this.loadFavourites();
            this.getProfessionsRecommended();
          }
          this.loading = false;
          return;
        });
    }
  }

  public setProfessionsByCategory(): void {
    if (this.categoryList) {
      for (const category of this.categoryList) {
        this.professionsByCategory[category?.name] = this.professionList.filter(
          (profession: IProfession) => profession.fields[0]?.name === category.name,
        );
      }
    }
  }

  public showDetailProfession(profession: IProfession): void {
    this.selectProfession = profession;
  }

  private getProfessionsRecommended(): void {
    this.sessionIdsInterests &&
      this.tinderService
        .getProfessions({ tinderType: B2gTinderTypes.Interests })
        .pipe(
          tap((results: IGetProfessionsResponse) => {
            if (results && results?.professions?.length) {
              this.results = results;
              this.recomProfessions = results.professions;
              this.recomProfessions.forEach(prof => {
                this.recommended[prof.id] = true;
                if (this.professionListFiltered.length) {
                  this.recommendedList.push(this.professionListFiltered.find(el => el.id === prof.id));
                }
                this.recommendedArray.push(prof.id);
              });
            }
            if (this.applyFilters.interests) {
              this.applyFilter();
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();

    this.sessionIdsCapabilities &&
      this.tinderService
        .getProfessions({ tinderType: B2gTinderTypes.Capabilities })
        .pipe(
          tap((results: IGetProfessionsResponse) => {
            if (results && results?.professions?.length) {
              this.results = results;
              this.recomProfessions = results.professions;
              this.recomProfessions.forEach((prof: IProfession) => {
                this.recommendedCapabilities[prof.id] = true;
                if (this.professionListFiltered.length) {
                  this.recommendedListCapabilities.push(this.professionListFiltered.find((el: IProfession) => el.id === prof.id));
                }
                this.recommendedArrayCapabilities.push(prof.id);
              });
            }
            if (this.applyFilters.capabilities) {
              this.applyFilter();
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    if (this.applyFilters.category) {
      this.applyFilter();
    }
  }

  public getProfessionsByOrder(value): any {
    const professionList = [...value];
    const cyrillicNamesDesc: any[] = professionList
      .filter(prof => /[А-Яа-я]/.test(prof?.name[0]))
      .sort((a, b) => a.name.localeCompare(b.name));
    const latinNamesDesc: any[] = professionList
      .filter(prof => /[A-Za-z]/.test(prof?.name[0]))
      .sort((a, b) => a.name.localeCompare(b.name));
    const otherNamesDesc: any[] = professionList
      .filter(prof => /[^А-Яа-яA-Za-z]/.test(prof?.name[0]))
      .sort((a, b) => a.name.localeCompare(b.name));
    this.professionListFilteredView = [];
    this.countElement = 0;
    return [...cyrillicNamesDesc, ...latinNamesDesc, ...otherNamesDesc];
  }

  public clearSearch(): void {
    this.searchClear = true;
  }

  private getCategoryList(): void {
    this.apiFieldsService
      .getAllFields(this.fieldType)
      .pipe(
        take(1),
        map(response => response.fields),
        takeUntil(this.unsubscribe),
      )
      .subscribe((fields: any) => {
        this.categoryList = [];
        fields.forEach(field => {
          if (this.filteredCategoryId && field.id === this.filteredCategoryId) {
            this.applyFilters.category = true;
            this.webStorageService.set(StorageKeys.FilteredProfessionCategoryId, null);
            this.filteredCategory = [
              {
                name: field.name,
                count: 0,
                id: field.id,
                selected: true,
                isOpen: true,
              },
            ];
          }
          this.categoryList.push({
            name: field.name,
            count: 0,
            id: field.id,
            selected: false,
            isOpen: true,
          });
        });
        this.categoryList = this.getProfessionsByOrder(this.categoryList);
        if (!this.professionsDataHandlerService.getCategoryList().value) {
          this.professionsDataHandlerService.setCategoryList(this.categoryList);
        }
        this.loadedCategory = true;
        this.getProfessionCatalog();
      });
  }

  public openCategory(category: ProfessionFilter): void {
    category.isOpen = !category.isOpen;
  }

  public closeBvbRecommendation(): void {
    this.showBvbRecommendations = false;
  }

  public createClassesFormat(variants: IVariant[]): void {
    this.closeBvbRecommendation();

    const request = {
      classesFormatsForProfession: {
        userId: this.userInfo.userId,
        professionId: this.eventProf.profession.id,
        classFormats: variants.map(variant => variant.text),
      },
    };
    this.apiProfilesService.createClassesFormatsForProfessions(request).pipe(take(1), takeUntil(this.unsubscribe)).subscribe();
    this.favouritesList.push(this.eventProf.profession);
    this.favourites[this.eventProf.profession.id] = this.eventProf.profession.id;
  }

  public loadFavourites(): void {
    this.favouritesList = [];
    const favorites = this.favoritesDataHandlerService.getFavoriteProfessions().getValue();

    favorites?.forEach(prof => {
      this.favourites[prof.productId] = prof.id;
      if (this.professionListFiltered.length) {
        this.favouritesList.push(this.professionListFiltered.find((el: IProfession) => el.id === prof.productId));
      }
    });

    if (this.applyFilters.favourite) {
      this.applyFilter();
    }
  }

  public addCategoryFilter(category: ProfessionFilter): void {
    if (category.selected) {
      this.filteredCategory.splice(this.filteredCategory.indexOf(category), 1);
      if (!this.filteredCategory.length) {
        this.applyFilters.category = false;
      }
    } else if (!this.filteredCategory.find(categoryItem => categoryItem.id === category.id)) {
      this.filteredCategory.push(category);
      this.applyFilters.category = true;
    }
    category.selected = !category.selected;
  }

  public showFilter(): void {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.syncFiltersStates('byApplied');
    }
    this.dataSearch('');
  }

  private syncFiltersStates(flag: 'byActive' | 'byApplied'): void {
    if (flag === 'byApplied') {
      for (const key in this.applyFilters) {
        this.applyFilters[key] = this.filtersState[key];
      }
      if (!this.filtersState.appliedCategories.length) {
        this.categoryList.forEach((category: ProfessionFilter) => (category.selected = false));
      } else if (
        this.filtersState.appliedCategories.length &&
        this.filtersState.appliedCategories.length !== this.categoryList.filter((category: ProfessionFilter) => category.selected).length
      ) {
        const appliedCategoriesId = this.filtersState.appliedCategories.map(category => category.id);
        this.categoryList.forEach((category: ProfessionFilter) =>
          appliedCategoriesId.includes(category.id) ? (category.selected = true) : (category.selected = false),
        );
      }
    } else {
      for (const key in this.applyFilters) {
        this.filtersState[key] = this.applyFilters[key];
      }
    }
  }

  public resetFilters(): void {
    this.filtersState.favourite = false;
    this.filtersState.capabilities = false;
    this.filtersState.interests = false;
    if (this.filteredCategory.length) {
      this.resetCategoryFilter();
    }
    this.applyFilter();
  }

  private resetCategoryFilter(): void {
    this.filteredCategory.forEach((category: ProfessionFilter) => (category.selected = false));
    this.filteredCategory = [];
    this.applyFilters.category = false;
    this.filtersState.category = false;
  }

  public closeFiltersWithReset(): void {
    this.applyFilters.favourite = false;
    this.applyFilters.capabilities = false;
    this.applyFilters.interests = false;
    this.resetFilters();
    this.showFilter();
  }

  public closeWithApply(): void {
    this.applyFilter();
    this.showFilter();
  }

  public applyFilter(): void {
    this.sortProfessionsList = [];
    if (this.applyFilters.favourite || this.applyFilters.interests || this.applyFilters.capabilities) {
      this.setFilteredProfByCategory();
      this.isFilters = true;
    }
    if (!this.applyFilters.favourite && this.applyFilters.category && !(this.applyFilters.interests || this.applyFilters.capabilities)) {
      for (const category of this.filteredCategory) {
        this.professionsByCategory[category?.name].forEach((el: IProfession) => {
          this.sortProfessionsList.push(el);
        });
      }
      this.isFilters = true;
      this.currentFilterCategory = [...this.filteredCategory];
      this.filtersState.appliedCategories = [...this.filteredCategory];
      this.currentFilterProfByCategory = this.professionsByCategory;
      this.filtersState.category = true;
    }
    if (!this.applyFilters.favourite && !this.applyFilters.category && !(this.applyFilters.interests || this.applyFilters.capabilities)) {
      this.isFilters = false;
    }
    this.syncFiltersStates('byActive');
  }

  public showOnlyFavorites(): void {
    this.applyFilters.favourite = !this.applyFilters.favourite;
  }

  public showWithCapabilities(): void {
    this.applyFilters.capabilities = !this.applyFilters.capabilities;
  }

  public showWithInterests(): void {
    this.applyFilters.interests = !this.applyFilters.interests;
  }

  public closeOnlyFavorites(): void {
    this.filtersState.favourite = false;
    this.showOnlyFavorites();
    this.applyFilter();
  }

  public closeRecommended(filter: string): void {
    if (filter === 'interests') {
      this.filtersState.interests = false;
      this.showWithInterests();
    } else {
      this.filtersState.capabilities = false;
      this.showWithCapabilities();
    }
    this.applyFilter();
  }

  private getBvbFavorite(): void {
    this.apiProfilesService
      .getClassesFormatsForProfessionByUserId(this.userInfo.userId)
      .pipe(
        map(response => response.classesFormatsForProfessions),
        filter((classesFormatsForProfessions: IClassesFormatsForProfessions[]) => {
          if (classesFormatsForProfessions?.length > 0) {
            return true;
          }

          this.favouritesList = [];
          return false;
        }),
        map((classesFormatsForProfessions: IClassesFormatsForProfessions[]) =>
          classesFormatsForProfessions.map(
            (classesFormatsForProfession: IClassesFormatsForProfessions) => classesFormatsForProfession.professionId,
          ),
        ),
        switchMap((professionIds: string[]) =>
          this.apiProfessionsService.getElasticProfessionsByFilters({ ids: professionIds, isVisible: true }),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe((professions: IProfession[]) => {
        this.favouritesList = professions;
        this.favouritesList.forEach((prof: IProfession) => {
          this.favourites[prof.id] = prof.id;
        });
      });
  }

  public setFilteredProfByCategory(): void {
    const currentCategories: ProfessionFilter[] = this.applyFilters.category ? this.filteredCategory : this.categoryList;
    let recommendedArray: string[] = [];
    let recommendedList: IProfession[] = [];
    if (this.applyFilters.capabilities && this.applyFilters.interests) {
      recommendedArray = [...this.recommendedArrayCapabilities];
      recommendedList = [...this.recommendedListCapabilities];
      this.recommendedArray.forEach((el: string) => {
        if (!this.recommendedArrayCapabilities.includes(el)) {
          recommendedArray.push(el);
        }
      });
      this.recommendedList.forEach((el: IProfession) => {
        if (!this.recommendedListCapabilities.includes(el)) {
          recommendedList.push(el);
        }
      });
      this.filtersState.capabilities = true;
      this.filtersState.interests = true;
    } else if (this.applyFilters.interests) {
      recommendedArray = this.recommendedArray;
      recommendedList = this.recommendedList;
      this.filtersState.interests = true;
    } else if (this.applyFilters.capabilities) {
      recommendedArray = this.recommendedArrayCapabilities;
      recommendedList = this.recommendedListCapabilities;
      this.filtersState.capabilities = true;
    }
    let currentFavourites: IProfession[] = [];
    if (this.applyFilters.favourite) {
      if (this.applyFilters.interests || this.applyFilters.capabilities) {
        currentFavourites = this.favouritesList.filter((prof: IProfession) => recommendedArray.includes(prof?.id));
      } else {
        currentFavourites = this.favouritesList;
      }
      this.filtersState.favourite = true;
    } else {
      currentFavourites = recommendedList;
    }
    if (this.categoryList) {
      this.filteredFavouriteCategory = [];
      let professionByCategory: IProfession[] = [];
      for (const category of currentCategories) {
        professionByCategory = currentFavourites.filter((profession: IProfession) => profession?.fields[0]?.name === category.name);
        if (professionByCategory.length) {
          this.professionsFavouriteByCategory[category?.name] = professionByCategory;
          professionByCategory.forEach((el: IProfession) => this.sortProfessionsList.push(el));
          category.isOpen = true;
          this.filteredFavouriteCategory.push(category);
        }
      }
      this.currentFilterCategory = this.filteredFavouriteCategory;
      this.currentFilterProfByCategory = this.professionsFavouriteByCategory;
    }
  }

  public goToLessons(): void {
    this.webStorageService.set(StorageKeys.Onbording, false);
    this.webStorageService.set(StorageKeys.FirstVisitLessons, true);
    this.router.navigate(['/pupil']);
  }

  public updateFavorites(event?: FavouriteEvent): void {
    const selectProfession: IProfession = event ? event.profession : this.selectProfession;
    if (this.isBvb) {
      if (this.favourites[selectProfession.id]) {
        this.apiProfilesService
          .deleteClassesFormatsForProfessions(this.userInfo.userId, selectProfession.id)
          .pipe(take(1), takeUntil(this.unsubscribe))
          .subscribe();
        this.favourites[selectProfession.id] = false;
      } else {
        this.showBvbRecommendations = true;
      }
    } else {
      if (this.favourites[selectProfession.id]) {
        this.deleteFromFavorite(selectProfession);
      } else {
        this.addToFavorites(selectProfession);
      }
    }
  }

  public addToFavorites(selectProfession: IProfession): void {
    this.showBvbRecommendations = false;
    this.apiFavoritesService
      .addToFavorites(selectProfession.id, '3', this.userInfo.userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: IFavoriteProfessionInterface) => {
        this.favourites[selectProfession.id] = response.id;
        this.favouritesList.push(selectProfession);
        this.favoritesDataHandlerService.addToFavorites({ productId: selectProfession.id, id: response.id });

        const uaParams: IData = {
          ProfessionId: selectProfession.id,
        };
        const ymParams: IData = {
          event_label: { Profession: selectProfession.id },
        };
        this.userActionsService.log(YmItems.S_ProfessionsCard_Likes, uaParams, ymParams);
      });
  }

  public deleteFromFavorite(selectProfession: IProfession): void {
    this.favoritesDataHandlerService.deleteFavoriteProfession(this.favourites[selectProfession.id]);
    this.favourites[selectProfession.id] = false;
    this.yandexMetricsService.reachGoal(YmItems.S_ProfessionsCard_NotLikes);
    this.favouritesList.splice(this.favouritesList.indexOf(selectProfession), 1);
  }
}
