import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys, UserActionsService, UserDataHandlerService, WebStorageService, YmItems } from '@profilum-library';
import { IButtonOnbording, IMessageOnbording } from '../../../../../shared/interfaces/common/ondording.interface';
import { OnbordingButtonsTypes } from '../fitting-professions-onbording/fitting-professions-onbording.component';

@Component({
  selector: 'prf-pupil-welcome',
  templateUrl: './pupil-welcome.component.html',
  styleUrls: ['./pupil-welcome.component.scss'],
})
export class PupilWelcomeComponent {
  public messages: IMessageOnbording[];
  public buttons: IButtonOnbording[] = [{text: 'Начнем!', type: OnbordingButtonsTypes.Next}];
  public name: string = '';

  constructor(private webStorageService: WebStorageService,
              private router: Router, private userActionsService: UserActionsService,
              private userDataHandlerService: UserDataHandlerService,
  ) {
    this.name = this.userDataHandlerService.getUserData().getValue()?.firstName;

    this.userActionsService.setInitializationTime([YmItems.Pu_Reg_Introduction_ClickEnterButton]);

    this.messages = [
      {
        text:
          '<p class="show-messages-left-position-text"><span class="show-messages-title-text">Привет, ' + this.name + '!</span></p>',
      },
      {
        text:
          '<p class="show-messages-left-position-text"><span class="show-messages__message">Рады, что ты с нами!</span></p>',
      },
      {
        text:
          '<p class="show-messages-left-position-text"><span class="show-messages__message">Сейчас расскажем, что тебя ждет дальше.</span></p>',
      },
    ];

  }

  public buttonsActions(): void {
    this.webStorageService.set(StorageKeys.Onbording, true);
    this.userActionsService.log(YmItems.Pu_Reg_Introduction_ClickEnterButton);
    this.router.navigate(['/pupil']);
  }
}
