import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  ApiAuthService,
  ApiProfilesService,
  AuthHandlerService,
  B2gSaasService,
  Helper,
  IChangeUserPassword,
  IResponseChangedPassword,
  IUserInfo,
  RamStorageService,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { TranslateService } from '@ngx-translate/core';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { IChildrenInfo } from 'app/shared/interfaces/iuserinfo.interface';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IncorrectPasswordMessages } from 'app/shared/enums/passwordErrors.enum';

export const newPasswordErrors: Map<string, string> = new Map<IncorrectPasswordMessages, string>([
  [IncorrectPasswordMessages.ShortPassword, 'Пароль должен быть не менее 6 символов'],
  [IncorrectPasswordMessages.NoLowercase, "Пароль должен иметь по крайней мере одну букву ('a'-'z')"],
  [IncorrectPasswordMessages.NoDigit, "Пароль должен иметь как минимум одну цифру ('0'-'9')"],
]);

@Component({
  selector: 'prf-pupil-mobile-edit-profile',
  templateUrl: './pupil-mobile-edit-profile.component.html',
  styleUrls: ['./pupil-mobile-edit-profile.component.scss'],
})
export class PupilMobileEditProfileComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public avatarForm: FormGroup;
  public passwordForm: FormGroup;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  public userRestriction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('content', { read: ViewContainerRef }) public content: ViewContainerRef;
  @ViewChild('defaultTemplate') public defaultTemplate: TemplateRef<any>;
  @ViewChild('passwordEdit') public passwordEdit: TemplateRef<any>;
  @ViewChild('avatarEdit') public avatarEdit: TemplateRef<any>;
  public userBirthday: string = '';
  public changedPasswordDate: Date;
  public submitted: boolean = false;
  public submittedPassForm: boolean = false;
  public checkEmail: boolean = true;
  public duplicateUserName: boolean = false;
  public changedPassword: boolean = false;
  public passwordSaving: boolean = false;

  public readonly dateMask: (string | RegExp)[] = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly phoneMask: (string | RegExp)[] = [
    '+',
    '7',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ];
  public isMaskedPassword: boolean = true;
  public errorOldPass: string = '';
  public errorPass: string = '';
  public readonly CHANGE_PASS_RU: { en: string; ru: string }[] = [
    {
      en: 'Incorrect password.',
      ru: '',
    },
    { en: 'Passwords must be at least 6 characters.', ru: 'Пароль должен быть не менее 6 символов.' },
    {
      en: "Passwords must have at least one lowercase ('a'-'z').",
      ru: "Пароль должен иметь по крайней мере одну букву ('a'-'z').",
    },
    {
      en: "Passwords must have at least one digit ('0'-'9').",
      ru: "Пароль должен иметь как минимум одну цифру ('0'-'9').",
    },
  ];
  public userInfo: IUserInfo;
  public userRole: string;
  public isMale: boolean = false;
  public errorChangePass: string = '';
  public isMaskedPasswordOld: boolean = true;
  public rusLettersError: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public charactersError: boolean = true;
  public letterError: boolean = true;
  public whiteSpaceError: boolean = false;
  public numberError: boolean = true;
  public mosruUser: boolean = false;
  public children: IChildrenInfo[] = [];
  public initials: string = '';
  private password: string = '';
  private emailRegExp: RegExp = REG_EXP.emailRegExp;
  public isHideProfileButtons: boolean = false;

  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneCapitalLetter: RegExp = REG_EXP.testOneCapitalLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiProfilesService: ApiProfilesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private ramStorageService: RamStorageService,
    private b2gSaasService: B2gSaasService,
    private changeDetector: ChangeDetectorRef,
    private authHandlerService: AuthHandlerService,
    private userDataHandlerService: UserDataHandlerService,
    private apiAuthService: ApiAuthService,
  ) {
    super();
    this.userDataHandlerService
      .getUserData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userData => (this.userInfo = userData));
    this.userRestriction.next(this.userDataHandlerService.isUserRestricted());
    this.initials = (this.userInfo.firstName[0] + this.userInfo.lastName[0]).toUpperCase();
  }

  public ngOnInit(): void {
    const isRestricted: boolean = this.userRestriction.value;
    this.form = this.fb.group({
      lastName: new FormControl<string | null>({
        value: this.userInfo.lastName,
        disabled: isRestricted
      }, [Validators.required]),
      firstName: new FormControl<string | null>({
        value: this.userInfo.firstName,
        disabled: isRestricted
      }, [Validators.required]),
      middleName: new FormControl<string | null>({value: this.userInfo.middleName, disabled: isRestricted}),
      phone: new FormControl<string | null>({value: '+' + this.userInfo.phoneNumber, disabled: isRestricted}, [
        Validators.required,
        Validators.pattern(this.phoneRegExp),
      ]),
      email: new FormControl<string | null>({
        value: this.userInfo.email,
        disabled: isRestricted
      }, [Validators.pattern(this.emailRegExp)]),
      date: new FormControl<string | null>(
        {
          value: DateHelper.toDayJsInUTC(this.userInfo.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          disabled: isRestricted
        },
        [Validators.required, Validators.pattern(this.dateRegExp)],
      ),
      gender: new FormControl<string | null>({
        value: this.userInfo.gender,
        disabled: isRestricted
      }, [Validators.required]),
      password: new FormControl<string | null>({ value: 'Password', disabled: true }, [Validators.required]),
    });
    this.passwordForm = this.fb.group({
      oldPassword: new FormControl<string | null>(null, [Validators.required]),
      newPassword: new FormControl<string | null>(null, [Validators.required]),
    });
    // this.userBirthday = PerformanceLogger.toDayJsInUTC(this.userInfo.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
    this.avatarForm = this.fb.group({
      photo: new FormControl(this.userInfo.imagePath, [Validators.required]),
    });
    this.b2gSaasService
      .getChangePasswordDate()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(date => {
        if (date.passwordUpdatedDate.indexOf('0001') !== 0) {
          this.changedPasswordDate = date.passwordUpdatedDate?.toLocaleString();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.changeViewContent(this.defaultTemplate.createEmbeddedView(null));
    this.changeDetector.detectChanges();
  }

  private changeViewContent(view: ViewRef): void {
    this.content.clear();
    this.content.insert(view);
  }

  public close(): void {
    this.router.navigate(['/pupil-profile']);
  }

  public logout(): void {
    this.authHandlerService.logout();
  }

  public checkFormatEmail(event: Event): boolean {
    if (event && this.form.value.email) {
      this.checkEmail = this.emailRegExp.test(this.form.value.email);
      if (this.duplicateUserName) {
        this.duplicateUserName = !this.duplicateUserName;
      }
      return;
    }
  }

  public get formControls(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  public get formPasswordControls(): Record<string, AbstractControl> {
    return this.passwordForm.controls;
  }

  public toggleMaskOld(): void {
    this.isMaskedPasswordOld = !this.isMaskedPasswordOld;
    this.showHidePassword('oldPassword');
  }

  public focusOutErrorChecking(): void {
    if (this.form.value.password != '') {
      this.focusOutPasswordErrors = this.charactersError || this.letterError || this.numberError;
    }
  }

  public toggleMask(): void {
    this.isMaskedPassword = !this.isMaskedPassword;
    this.showHidePassword('newPassword');
  }

  public checkPhone(): void {
    const phone: string = this.formControls.phone.value.replace(/\D/g, '');

    if (phone !== this.userInfo.phoneNumber) {
      this.apiAuthService
        .checkPhoneAvailability(phone)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(response => {
          if (!response.free) {
            this.formControls.phone.setErrors({ isUsed: true });
          }
        });
    }
    if (!Helper.isValidPhoneNumber(phone)) {
      phone?.length < 11 ? this.formControls.phone.setErrors({ minLength: true }) : this.formControls.phone.setErrors({ pattern: true });
      return;
    }
  }

  public submitChanges(): void {
    if (!this.changedPassword) {
      // выключаем валидацию, если пользователь не менял дату
      if (this.form.controls['date'].pristine) {
        this.form.controls.date.setValidators([Validators.nullValidator]);
        this.form.controls.date.updateValueAndValidity();
      }
      this.submitted = true;

      if (!this.form.valid) {
        return;
      }

      this.webStorageService.set(StorageKeys.LastName, this.form.value.lastName);
      this.webStorageService.set(StorageKeys.FirstName, this.form.value.firstName);
      const replacedPhoneNumber: string = this.form.value.phone.replace(/\D/g, '');

      if (
        replacedPhoneNumber !== this.userInfo.phoneNumber ||
        this.form.value.date !== this.userBirthday ||
        this.form.value.lastName !== this.userInfo.lastName ||
        this.form.value.firstName !== this.userInfo.firstName ||
        this.form.value.middleName !== this.userInfo.middleName ||
        this.form.value.gender !== this.userInfo.gender ||
        this.form.value.email !== this.userInfo.email
      ) {
        this.userInfo.phoneNumber = replacedPhoneNumber;
        this.userInfo.birthday = DateHelper.toDayJsInUTC(this.form.value.date, 'DD/MM/YYYY').format();
        this.userInfo.lastName = this.form.value.lastName;
        this.userInfo.firstName = this.form.value.firstName;
        this.userInfo.middleName = this.form.value.middleName;
        this.userInfo.gender = this.form.value.gender;
        this.userInfo.email = this.form.value.email;

        this.apiProfilesService
          .updateUserProfile(this.userInfo)
          .pipe(
            tap(() => {
              this.userBirthday = DateHelper.toDayJsInUTC(this.userInfo.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
              this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
              this.userDataHandlerService.updateUserInfo();
            }),
            catchError(err => {
              return this.getTranslation('SHARED.SOMETHING_WENT_WRONG').pipe(
                switchMap((translation: string) => {
                  console.error(translation);
                  return throwError(err);
                }),
              );
            }),
            takeUntil(this.unsubscribe),
          )
          .subscribe(() => this.router.navigate(['/pupil-profile']));
      } else {
        this.router.navigate(['/pupil-profile']);
      }
    } else {
      this.submittedPassForm = true;
      if (this.passwordForm.valid) {
        this.updatePassword();
      }
    }
  }

  public canselChanges(): void {
    if (this.changedPassword) {
      this.changedPassword = false;
    } else {
      this.router.navigate(['/pupil-profile']);
    }
  }

  public getTranslation(key: string): Observable<string> {
    return <Observable<string>>this.translateService.get(key);
  }

  public changePasswordEditor(toOpen: boolean): void {
    const view = toOpen ? this.passwordEdit.createEmbeddedView(null) : this.defaultTemplate.createEmbeddedView(null);
    this.changeViewContent(view);
    this.changedPassword = !this.changedPassword;
    this.passwordSaving = false;
  }

  public showHidePassword(id: string): void {
    const input: HTMLElement = document.getElementById(id);
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
    } else {
      input.setAttribute('type', 'password');
    }
  }

  public openImageEditor(event: Event): void {
    try {
      if (!(event.target as HTMLInputElement).files.item(0)?.type.match('image.*')) {
        this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
        return;
      }

      this.ramStorageService.set(StorageKeys.UploadedOriginalUserPhoto, (event.target as HTMLInputElement).files);
      this.content.clear();
      this.content.insert(this.avatarEdit.createEmbeddedView(null));
      this.isHideProfileButtons = true;
    } catch (e) {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
      console.error(e);
    }
  }

  public clearPassErrors(controlName: string): void {
    if (controlName === 'newPassword' && this.errorPass) {
      this.errorPass = '';
    }
    if (controlName === 'oldPassword' && this.errorOldPass) {
      this.errorOldPass = '';
    }
  }

  private updatePassword(): void {
    const model: IChangeUserPassword = {
      currentPassword: this.passwordForm.value.oldPassword,
      newPassword: this.passwordForm.value.newPassword,
      newPassword2: this.passwordForm.value.newPassword,
    };

    this.errorChangePass = '';
    if (!this.focusOutPasswordErrors) {
      this.b2gSaasService
        .changeUserPassword(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((changeResult: IResponseChangedPassword) => {
          if (changeResult) {
            if (changeResult.status === 'Success') {
              this.passwordSaving = true;
              this.timer = setTimeout(() => {
                this.router.navigate(['/login']);
              }, 2000);
            } else {
              if (changeResult.comment) {
                if (changeResult.comment.includes('Incorrect password')) {
                  this.errorOldPass = 'Неверный пароль';
                  this.errorPass = '';
                } else {
                  this.errorPass = newPasswordErrors.get(changeResult.comment);
                  this.errorOldPass = '';
                  this.errorChangePass = this.translateChangePassRequest(changeResult.comment);
                }
              }
            }
          }
        });
    }
  }

  private translateChangePassRequest(text: string): string {
    const findText = this.CHANGE_PASS_RU.filter(cp => cp.en === text);
    if (findText && findText.length) {
      return findText[0].ru;
    } else {
      return text;
    }
  }

  public closeAvatarEdit(): void {
    setTimeout(() => {
      this.changeViewContent(this.defaultTemplate.createEmbeddedView(null));
      this.isHideProfileButtons = false;
    }, 2200);
  }
  public testNewPassword(): void {
    const newPassword = this.passwordForm.value.newPassword;

    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;

    if (newPassword && newPassword.length > 0) {
      this.charactersError = !this.testSixCharter.test(newPassword);
      this.letterError = !this.testOneLetter.test(newPassword) || !this.testOneCapitalLetter.test(newPassword);
      this.numberError = !this.testOneDigit.test(newPassword);
    }
  }
}
