import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiProfilesService,
  AuthHandlerService,
  B2gSaasService,
  ISchool,
  ISchoolClass,
  IUserInfo,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { IChildrenInfo, IParentInfo } from 'app/shared/interfaces/iuserinfo.interface';
import { DateFormatService } from '../../../ui-kit/services/utils-services/date-format.service';

@Component({
  selector: 'prf-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  public CHANGE_PASS_RU = [
    {
      en: 'Incorrect password.',
      ru: '',
    },
    { en: 'Passwords must be at least 6 characters.', ru: 'Пароль должен быть не менее 6 символов.' },
    {
      en: "Passwords must have at least one lowercase ('a'-'z').",
      ru: "Пароль должен иметь по крайней мере одину букву ('a'-'z').",
    },
    {
      en: "Passwords must have at least one digit ('0'-'9').",
      ru: "Пароль должен иметь как минимум одну цифру ('0'-'9').",
    },
  ];
  public school: ISchool;
  public userClass: ISchoolClass;
  public isParent: boolean = false;
  public userInfo: IUserInfo;
  public animIndex: number = 0;
  public parents: IParentInfo[] = [];
  public showInfoPopup: boolean = false;
  public userRole: string;
  public isInviteVisible: boolean = false;
  public uploadImageError: string = '';
  public isMale: boolean = false;
  public userBirthday: string = '';
  public changePasswordFlag: boolean = false;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public errorChangePass: string = '';
  public errorOldPass: string = '';
  public isMaskedPassword: boolean = true;
  public isMaskedPasswordOld: boolean = true;
  public rusLettersError: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public charactersError: boolean = true;
  public letterError: boolean = true;
  public whiteSpaceError: boolean = false;
  public numberError: boolean = true;
  public mosruUser: boolean = false;
  public children: IChildrenInfo[] = [];
  public passwordError: boolean = false;
  private password: string = '';
  private promotestResults: any[] = [];
  private readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  private readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  private readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  private readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  private readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  private readonly testOneCapitalLetter: RegExp = REG_EXP.testOneCapitalLetter;
  public animationKeys = {
    basic: '',
    closed: 'closed_',
    toOpenLeft: 'toOpen_',
    toCloseLeft: 'toClose_',
    toOpenRight: 'toOpen',
    toCloseRight: 'toClose',
  };
  public userAvatar: string;
  public commonProfileCardAnimation: string = this.animationKeys.basic;
  public editProfileCardAnimation: string = this.animationKeys.closed;
  public isHovered: boolean = false;
  public userRestriction: boolean = false;

  @ViewChild('content', { read: ViewContainerRef }) public content: ViewContainerRef;
  @ViewChild('defaultTemplate') public defaultTemplate: TemplateRef<any>;
  @ViewChild('avatarEdit') public avatarEdit: TemplateRef<any>;

  @ViewChild('nameContainer') public nameContainer: ElementRef;

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private apiProfilesService: ApiProfilesService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private dateFormatService: DateFormatService,
    private userActionsService: UserActionsService,
    private userDataHandlerService: UserDataHandlerService,
    private authHandlerService: AuthHandlerService,
    private ramStorageService: RamStorageService,
    private changeDetector: ChangeDetectorRef,
    private overlayBusyService: OverlayBusyService,
  ) {
    super();
    this.meta.updateTag({ name: 'og:title', content: 'Профайл' });
    this.userRole = userDataHandlerService.getUserInfo().role;
    this.isParent = this.userRole === 'parent';
    this.mosruUser = webStorageService.get(StorageKeys.Tag) === 'MosRu';
    this.userRestriction = userDataHandlerService.isUserRestricted();

    this.getTranslation('SHARED.INCORRECT_OLD_PASSWORD')
      .pipe(take(1))
      .subscribe(translation => (this.CHANGE_PASS_RU[0].ru = translation));

    this.userActionsService.setInitializationTime([YmItems.T_Profile_EditButton, YmItems.T_Profile_SaveChanges]);
  }

  public ngOnInit(): void {
    this.initForm();
    this.getUserData();
  }

  public ngAfterViewInit(): void {
    this.changeViewContent(this.defaultTemplate.createEmbeddedView(null));
    this.changeDetector.detectChanges();
  }

  public initForm(): void {
    this.form = this.fb.group({
      password: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
      passwordOld: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
    });
  }

  private getUserData(): void {
    this.b2gSaasService
      .getUserInfo()
      .pipe(
        tap(value => {
          if (value) {
            this.userInfo = value;
            this.userInfo.birthday = this.dateFormatService.formatDate(this.userInfo.birthday);
            this.children = this.userInfo.children;
            this.getImageUrl(this.userInfo);
            if (this.isParent && this.mosruUser) {
              this.apiProfilesService.getSharedResults(this.userInfo.userId).pipe(
                tap(sharedResults => {
                  if (sharedResults.length > 0) {
                    this.promotestResults = sharedResults.filter(r => r.screeningTestId === TESTS_VARIANTS_IDS.promoTestMosRu);

                    this.children = this.children.map(child => {
                      return Object.assign(child, {
                        istestedMosru:
                          this.promotestResults.filter(oneResult => {
                            return oneResult.refferalUserId == child.userId;
                          }).length > 0,
                        sessionId:
                          this.promotestResults.filter(oneResult => {
                            return oneResult.refferalUserId === child.userId;
                          }).length > 0
                            ? this.promotestResults.filter(oneResult => {
                                return oneResult.refferalUserId === child.userId;
                              })[0].sessionId
                            : null,
                      });
                    });
                  }
                }),
              );
            } else if (this.userRole === 'pupil' || this.userRole === 'teacher') {
              this.parents = this.userInfo && this.userInfo.parents && this.userInfo.parents.length ? this.userInfo.parents : [];
            }
          }
        }),
      )
      .subscribe();
  }

  public logOut(): void {
    this.authHandlerService.logout();
  }

  public toggleInvite(flag: boolean = false): void {
    this.isInviteVisible = flag;
  }

  private changeViewContent(view: ViewRef): void {
    this.content.clear();
    this.content.insert(view);
  }

  public openImageEditor(event: Event): void {
    try {
      if (!(event.target as HTMLInputElement).files.item(0)?.type.match('image.*')) {
        this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
        return;
      }

      this.ramStorageService.set(StorageKeys.UploadedOriginalUserPhoto, (event.target as HTMLInputElement).files);
      this.content.clear();
      this.content.insert(this.avatarEdit.createEmbeddedView(null));
    } catch (e) {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
      console.error(e);
    }
  }

  public closeAvatarEdit(isNewPhoto: boolean = false): void {
    if (isNewPhoto) {
      this.overlayBusyService.show();
      setTimeout(() => {
        this.changeViewContent(this.defaultTemplate.createEmbeddedView(null));
        this.getImageUrl(this.userDataHandlerService.getUserData().getValue());
        this.overlayBusyService.hide();
      }, 2200);
    } else {
      this.changeViewContent(this.defaultTemplate.createEmbeddedView(null));
    }
  }

  public getImageUrl(user: any): void {
    this.userAvatar = user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }

  public getMale(): boolean {
    return this.userInfo.gender == 'M';
  }

  public enableEditProfile(): void {
    this.userActionsService.log(YmItems.T_Profile_EditButton);
    this.editProfileCardAnimation = this.animationKeys.toOpenLeft;
    this.commonProfileCardAnimation = this.animationKeys.toCloseRight;
  }

  public disableEditProfile(): void {
    this.editProfileCardAnimation = this.animationKeys.toCloseLeft;
    this.commonProfileCardAnimation = this.animationKeys.toOpenRight;
  }

  public cancelEdit(): void {
    this.form.get('password').setValue('');
    this.form.get('password').updateValueAndValidity();
    this.form.get('passwordOld').setValue('');
    this.form.get('passwordOld').updateValueAndValidity();
  }

  get formControls() {
    return this.form.controls;
  }

  public submitChanges(): void {
    this.submitted = true;
    if (!this.form.value.password || !this.form.value.passwordOld) {
      this.errorChangePass = 'Для смены пароля, введите старый и новый пароль.';
    } else if (this.form.value.password === this.form.value.passwordOld) {
      this.errorChangePass = 'Новый пароль не должен совпадать со старым';
    } else {
      this.updatePassword();
    }
  }

  public isAccessAllowed(): boolean {
    return this.testPassword(this.form.value.password);
  }

  public toggleMask(): void {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public toggleMaskOld(): void {
    this.isMaskedPasswordOld = !this.isMaskedPasswordOld;
  }

  public errorChecking(): boolean {
    if (this.passwordError) {
      return true;
    }
  }

  public focusOutErrorChecking(): void {
    if (this.form.value.password != '') {
      this.focusOutPasswordErrors =
        this.charactersError || this.letterError || this.numberError || this.rusLettersError || this.whiteSpaceError;
    }
  }

  public clearPassError(): void {
    this.errorChangePass = '';
    this.errorOldPass = '';
    this.focusOutPasswordErrors = false;
  }

  public checkTextOverflow(): void {
    const nameElement = this.nameContainer.nativeElement;
    if (nameElement.scrollWidth >= nameElement.clientWidth) {
      this.isHovered = true;
    }
  }

  private updatePassword(): void {
    const model = {
      currentPassword: this.form.value.passwordOld,
      newPassword: this.form.value.password,
      newPassword2: this.form.value.password,
    };

    this.errorChangePass = '';
    if (!this.focusOutPasswordErrors) {
      this.b2gSaasService
        .changeUserPassword(model)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(changeResult => {
          if (changeResult) {
            if (changeResult.status === 'Success') {
              this.changePasswordFlag = !this.changePasswordFlag;
              this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
              this.router.navigate(['/login']);
            } else {
              if (changeResult.comment) {
                if (changeResult.comment.includes('Incorrect password')) {
                  this.errorOldPass = 'Неверный старый пароль.';
                } else {
                  this.errorChangePass = this.translateChangePassRequest(changeResult.comment);
                }
              }
            }
          }
        });
    }
  }

  private translateChangePassRequest(text: string): string {
    const findText = this.CHANGE_PASS_RU.filter(cp => cp.en === text);
    if (findText && findText.length) {
      return findText[0].ru;
    } else {
      return text;
    }
  }

  private testPassword(event): boolean {
    this.passwordError = false;

    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;
    this.rusLettersError = false;
    this.whiteSpaceError = false;
    if (event && event.length > 0) {
      this.charactersError = !this.testSixCharter.test(event);
      this.letterError = !(this.testOneLetter.test(event) && this.testOneCapitalLetter.test(event));
      this.numberError = !this.testOneDigit.test(event);
      this.rusLettersError = this.testRusLetters.test(event);
      this.whiteSpaceError = !this.testWhiteSpace.test(event);

      switch (true) {
        case this.whiteSpaceError:
          return false;
        case this.charactersError:
          this.passwordError = true;
          return false;
        case this.letterError:
          this.passwordError = true;
          return false;
        case this.numberError:
          this.passwordError = true;
          return false;
        case this.rusLettersError:
          this.passwordError = true;
          return false;
        default:
          return true;
      }
    }
  }

  private getTranslation(key: string): Observable<string> {
    return this.translateService.get(key);
  }
}
