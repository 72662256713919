import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms/';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject, timer } from 'rxjs';

import {
  ApiPlayerService,
  ApiUsersService,
  AppSettingsService,
  B2gSaasService,
  IDataSession,
  IUserProfileInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { EAnketaStep, ETestType } from 'app/pages/player/anketa/anketa.enums';
import { AnketaService } from 'app/pages/player/anketa/anketa.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { SlideService } from 'app/shared/services/slide.service';
import { ETestTypes, ETestTypesSteps } from 'app/shared/enums/testtypes.enum';
import { AnketaTestTypes, TestTypeSetter } from 'app/pages/player/anketa/anketa.collections';
import { ResultsService } from 'app/pages/results/results.service';
import { MOSRED_ID } from 'app/shared/interfaces/regions-id.interface';
import { PlayerService } from '../player.service';
import { IsAnonymousService } from '../../../ui-kit/services/utils-services/is-anonymous.service';
import { TESTS_VARIANTS_IDS } from '../../../shared/global-constants/tests-variants.data';
import { Gender } from '../../../shared/enums/gender.enums';

@Component({
  selector: 'prf-anketa',
  templateUrl: './anketa.component.html',
  styleUrls: ['./anketa.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnketaComponent extends UnsubscribeComponent implements OnInit {
  public currentAnketaStep = 1;
  public steps = EAnketaStep;
  public forms: FormGroup[];
  public childInfoForm: FormGroup;
  public navigation: Subject<boolean>;
  public userRole: string;
  public userId: string;
  public testType: string = '';
  public testTypes = ETestTypes;
  public testTypesSteps = ETestTypesSteps;
  public isMincifryTest: boolean = false;
  private anketa: any = { testType: ETestType.Default };
  private language: string = 'ru';
  public isMOStend: boolean = false;

  private mosregId = MOSRED_ID;

  constructor(
    public anketaService: AnketaService,
    private router: Router,
    private apiUsersService: ApiUsersService,
    private b2gSaasService: B2gSaasService,
    private playerService: PlayerService,
    private slideService: SlideService,
    private overlayService: OverlayBusyService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private resultsService: ResultsService,
    private webStorageService: WebStorageService,
    private apiPlayerService: ApiPlayerService,
    private isAnonymousService: IsAnonymousService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();

    const userInfo: IUserProfileInfo = this.userDataHandlerService.getUserInfo();
    this.userRole = userInfo? userInfo.role: this.webStorageService.get(StorageKeys.UserRole);
    this.userId = userInfo? userInfo.userId: this.webStorageService.get(StorageKeys.UserId);

    this.isMOStend = this.mosregId === this.webStorageService.get(StorageKeys.RegionId);
  }

  public ngOnInit(): void {
    this.setTestType();
    this.forms = this.anketaService.getForms();
    this.childInfoForm = this.anketaService.childInfoForm;
    this.navigation = new Subject();
    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      params = params || {};
      if (params.type == 'child') {
        this.webStorageService.set(StorageKeys.SharedSession, params.id);
      } else {
        this.webStorageService.clear(StorageKeys.SharedSession);
      }
    });
    this.navigation
      .pipe(
        mergeMap(() => {
          const testType: string = this.webStorageService.get(StorageKeys.TestType);

          this.anketa.testType = AnketaTestTypes.has(testType)
            ? AnketaTestTypes.get(testType)(this.userRole)
            : AnketaTestTypes.get('default')(this.userRole);

          return this.createSession();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        () => {
          this.overlayService.hide();
        },
        err => {
          this.overlayService.hide();
          this.handleSessionError();
        },
      );
  }

  public changeLanguage(event: string): void {
    this.language = event ? event : 'ru';
  }

  public enableForward(): boolean {
    if (this.testType === ETestTypes.CAMP_TEST.toString()) {
      this.anketaService.childInfoForm;

      if (this.childInfoForm.invalid) {
        document.getElementById('childInfoForm')?.scrollIntoView({ behavior: 'smooth' });
      }

      return this.childInfoForm.valid && this.forms[this.currentAnketaStep - 1].valid;
    }

    return this.forms[this.currentAnketaStep - 1].valid;
  }

  private createSession(): Observable<any> {
    this.overlayService.show();
    let currentObservable$: Observable<any> = of(null);
    let testRoute: string = '';
    switch (this.anketa.testType) {
      case ETestType.Default:
        currentObservable$ = this.apiUsersService.createSessionPupils(this.anketa).pipe(
          switchMap(sessionResult => {
            if (!sessionResult || !sessionResult.status || sessionResult.status === 'Failed to create testing session') {
              this.handleSessionError();
              return of(null);
            } else {
              return timer(1500).pipe(
                switchMap(r => {
                  if (sessionResult.status == 'Success') {
                    if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                      this.webStorageService.set(StorageKeys.LastSession, {
                        sessionID: sessionResult.sessionId,
                        user: this.anketa,
                      });
                    }
                    const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
                    return this.apiPlayerService.getNextSlide(sessionResult.sessionId, isAnonymous).pipe(
                      tap(value => {
                        this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                        this.router.navigate(['/test-na-professiyu', sessionResult.sessionId]).then(() => {
                          this.overlayService.hide();
                        });
                      }),
                    );
                  } else {
                    return of(null);
                  }
                }),
              );
            }
          }),
        );
        break;
      case ETestType.Test360:
        this.anketa = {
          data: {
            user: {
              gender: this.webStorageService.get<string>(StorageKeys.UserGender)?.toLowerCase(),
            },
          },
          testType: ETestType.Test360,
        };
        if (this.anketa.testType === ETestType.Test360) {
          this.anketa.ReferralUserId = this.webStorageService.get(StorageKeys.RefferalUserId);
          const childGender: string = this.webStorageService.get<string>(StorageKeys.RefferalUserGender);
          if (childGender == Gender.Female || childGender == Gender.Male) {
            this.anketa.data.user.gender = childGender.toLowerCase();
          }
          this.anketa.data.ScreeningTestType = 'Screening360';
        }
        currentObservable$ = this.apiUsersService.createSessionParents(this.anketa).pipe(
          switchMap((sessionResult: any) => {
            return timer(1500).pipe(
              switchMap(r => {
                if (sessionResult.status == 'Success') {
                  if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                    this.webStorageService.set(StorageKeys.LastSession, {
                      sessionID: sessionResult.sessionId,
                      user: this.anketa,
                    });
                  }
                  const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
                  return this.apiPlayerService.getNextSlide(sessionResult.sessionId, isAnonymous).pipe(
                    tap(value => {
                      this.slideService.setCurrentSlide(sessionResult.sessionId, value);
                      this.router.navigate(['/test-na-professiyu', sessionResult.sessionId]).then(() => {
                        this.overlayService.hide();
                      });
                    }),
                  );
                } else {
                  if (sessionResult.comment == "Can't find child testing session") {
                    this.utilsService.openSnackBar('Ребенок еще не начал тестирование', 'error');
                  }
                  return of(null);
                }
              }),
            );
          }),
        );
        break;
      case ETestType.MotivationTest:
        const motivationTestId = TESTS_VARIANTS_IDS.motivationtest;
        const motivationTestData = {
          motivationTestId,
          userId: this.userId,
          personalInfo: {
            User: {
              Gender: Gender.Male,
            },
          },
        };

        currentObservable$ = this.apiPlayerService.createSession(motivationTestData).pipe(
          switchMap((sessionResult: any) => {
            let sessionId: string;
            if (sessionResult && sessionResult.status === 'Success') {
              sessionId = sessionResult.sessionId;
              return timer(1500).pipe(
                switchMap(() => {
                  if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                    this.webStorageService.set(StorageKeys.LastSession, {
                      sessionID: sessionId,
                      user: this.anketa,
                    });
                  }
                  const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
                  return this.apiPlayerService.getNextSlide(sessionId, isAnonymous).pipe(
                    tap(value => {
                      this.slideService.setCurrentSlide(sessionId, value);
                      this.router.navigate(['/motivation-test', sessionId]).then(() => {
                        this.overlayService.hide();
                      });
                    }),
                  );
                }),
              );
            } else {
              return of(null);
            }
          }),
        );
        break;
      case ETestType.ValuesTest:
        const testId = TESTS_VARIANTS_IDS.valuesTest;
        const data = {
          testId,
          userId: this.userId,
          personalInfo: {
            User: {
              Gender: Gender.Male,
            },
          },
        };

        currentObservable$ = this.apiPlayerService.createSession(data).pipe(
          switchMap((sessionResult: any) => {
            let sessionId: string;
            if (sessionResult && sessionResult.status === 'Success') {
              sessionId = sessionResult.sessionId;
              return timer(1500).pipe(
                switchMap(() => {
                  if (this.testType !== ETestTypes.PROFESSIONPRESTIGE.toString()) {
                    this.webStorageService.set(StorageKeys.LastSession, {
                      sessionID: sessionId,
                      user: this.anketa,
                    });
                  }
                  const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
                  return this.apiPlayerService.getNextSlide(sessionId, isAnonymous).pipe(
                    tap(value => {
                      this.slideService.setCurrentSlide(sessionId, value);
                      this.router.navigate(['/values-test', sessionId]).then(() => {
                        this.overlayService.hide();
                      });
                    }),
                  );
                }),
              );
            } else {
              return of(null);
            }
          }),
        );
        break;
      case ETestType.MincifrydemoTest:
        const mincifrydemoTestData = {
          screeningTestId: TESTS_VARIANTS_IDS.mincifrydemoTest,
          userId: this.userId,
        };

        testRoute = '/mincifrydemotest';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.MINCIFRYDEMO_TEST.toString());

        currentObservable$ = this.createTestSession(mincifrydemoTestData, testRoute);
        break;
      case ETestType.BvbTest:
        const bvbData = {
          screeningTestId: TESTS_VARIANTS_IDS.bvbTest,
          userId: this.userId,
          data: {},
        };

        testRoute = '/bvb-test';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.BVB_TEST.toString());

        currentObservable$ = this.createTestSession(bvbData, testRoute);
        break;
      case ETestType.VkTest:
        const vkTestData = {
          screeningTestId: TESTS_VARIANTS_IDS.vkTest,
          userId: this.userId,
        };

        testRoute = '/vk-test';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.VK_TEST.toString());

        currentObservable$ = this.createTestSession(vkTestData, testRoute);
        break;
      case ETestType.WebTest:
        const webTestData = {
          screeningTestId: TESTS_VARIANTS_IDS.vkTest,
          userId: this.userId,
        };

        testRoute = '/web-test';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.WEB_TEST.toString());

        currentObservable$ = this.createTestSession(webTestData, testRoute);
        break;
      case ETestType.CampTest:
        testRoute = '/camp-test';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.CAMP_TEST.toString());
        currentObservable$ = this.b2gSaasService
          .submitChildInfoForm(this.anketaService.getLeadDto)
          .pipe(take(1))
          .pipe(
            switchMap((lead: any) => {
              const campTestData = {
                userId: lead.leadId,
                screeningTestId: AppSettingsService.settings.campTestId,
                data: { User: { Gender: 'M' } },
              };
              return this.createTestSession(campTestData, testRoute);
            }),
          );
        break;
      case ETestType.MincifryTest:
        const mincifryData = {
          screeningTestId: TESTS_VARIANTS_IDS.mincifryTest,
          userId: this.userId,
          data: {},
        };

        testRoute = '/mincifry-test';
        this.webStorageService.set(StorageKeys.TestType, ETestTypes.MINCIFRY_TEST.toString());

        currentObservable$ = this.createTestSession(mincifryData, testRoute);
        break;
      default:
        currentObservable$ = of(null);
        break;
    }

    return currentObservable$;
  }

  private createTestSession(testData: IDataSession, route: string): Observable<any> {
    let sessionId: string = '';
    return this.apiPlayerService.createSession(testData).pipe(
      switchMap(sessionResult => {
        if (!sessionResult || sessionResult?.status !== 'Success') {
          this.handleSessionError();
          return of(null);
        }
        sessionId = sessionResult?.sessionId;
        return timer(1500);
      }),
      switchMap(() => {
        const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
        return this.apiPlayerService.getNextSlide(sessionId, isAnonymous);
      }),
      tap(value => {
        this.slideService.setCurrentSlide(sessionId, value);
        this.router.navigate([route, sessionId]).then(() => {
          this.overlayService.hide();
        });
      }),
    );
  }

  private setTestType(): void {
    this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
      url.forEach(segment =>
        TestTypeSetter.has(segment.path) ? TestTypeSetter.get(segment.path)(this.userRole, this.webStorageService) : null,
      );

      this.testType = this.webStorageService.get(StorageKeys.TestType)?.toString();
      if (this.testType == null) {
        if (this.userRole == 'pupil') {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
        if (this.userRole == 'parent') {
          this.testType = ETestTypes.DEFAULT_360.toString();
        } else {
          this.testType = ETestTypes.DEFAULT_STANDART.toString();
        }
      }

      this.isMincifryTest = this.testType === ETestTypes.MINCIFRY_TEST.toString();
    });
  }

  public startTest(): void {
    this.navigation.next(true);
    this.slideService.changeSlideNumber(1);
  }

  private handleSessionError(): void {
    this.utilsService.openSnackBar('Неполадки на сервере, уже чиним', 'error');
    setTimeout(() => {
      this.overlayService.hide();
      this.utilsService.closeSnackBar();
      return this.router.navigate(['/']);
    }, 3000);
  }
}
