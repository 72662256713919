import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, Meta } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, delay, takeUntil, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { institutionTypes } from 'app/shared/enums/institutiontypes.enum';

import {
  AdditionalEducationItems,
  SpecialitySelectionsByGeneral,
} from 'app/shared/interfaces/analyticsFilters/analyticfilters.interface';
import {
  ClassVariants,
  defaultAdditionalChartData,
  defaultChartData,
  education,
  professionsMatchingInnerHTML,
  profNavigationInnerHTML,
  talentDistributionInnerHTML,
  TRANSLATIONS,
} from './director-analytics.constants';
import { MatIconRegistry } from '@angular/material/icon';
import {
  ApiLocationsService,
  ApiResultsService,
  ApiUsersService,
  B2gSaasService,
  DistributionTypes,
  ISchool,
  IUserInfo,
  PupilsDistributionObject,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ChartTypesEnum, EducationChartTypesEnum, FILTER, FilterVariantsEnum, IBarChart, ReportEnum } from './models';
import { UtilsService } from '../../../../ui-kit/services/utils-services/utils.service';
import {
  IAnswerPercents,
} from '../../../../../../../../libs/profilum-library/src/lib/api-services/api-temporary/api-temporary.interface';

@Component({
  selector: 'prf-director-analytics',
  templateUrl: './director-analytics.component.html',
  styleUrls: ['./director-analytics.component.scss'],
})
export class DirectorAnalyticsComponent extends UnsubscribeComponent implements OnInit {
  public report: any;
  public municipalityReport: any;
  public showMunicipityReport: boolean = false;
  public filter: FILTER = null;
  public userMunicipality: any;
  public userData: IUserInfo;

  public viewData: any;
  public viewMunicipalityData: any;
  public school: ISchool;
  public isLoaded: boolean = false;
  public reportNotFound = false;
  public isUpdatingBase: boolean = false;
  public isUpdatingMunicipality: boolean = false;
  public translations: string[] = [];
  public chartEducationHeight: string;
  public chartSPOheight: string;
  public chartVPOheight: string;
  public isMenuFixed: boolean = false;
  protected readonly ReportEnum = ReportEnum;
  protected readonly education = education;

  public form: UntypedFormGroup = new UntypedFormGroup({
    comparefilter: new UntypedFormControl(null),
    filtertype: new UntypedFormControl(null),
    genderFilter: new UntypedFormControl(null),
    classFilter: new UntypedFormControl(null),
    performanceConsidering: new UntypedFormControl(null),
  });

  public educationFiltersForm: UntypedFormGroup = new UntypedFormGroup({
    educationBaseFilter: new UntypedFormControl(null),
    educationMunicipalityFilter: new UntypedFormControl(null),
  });

  public pupilDistributionType: typeof DistributionTypes = DistributionTypes;

  @ViewChild('municipalityProgressBars') public municipalityProgressBars: ElementRef;
  @ViewChild('chartEducationCanvas') public chartEducationCanvas: ElementRef;
  @ViewChild('contentContainer') public contentContainer: ElementRef;

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private apiUsersService: ApiUsersService,
    private apiResultsService: ApiResultsService,
    private apiLocationsService: ApiLocationsService,
    private translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private utilsService: UtilsService,
  ) {
    super();
    this.userData = this.userDataHandlerService.getUserData().getValue();
    this.meta.updateTag({ name: 'og:title', content: 'Аналитика по школе' });
    matIconRegistry.addSvgIcon(
      'checked_icon',
      sanitizer.bypassSecurityTrustResourceUrl('./profilum-assets/images/icons/checked_violet.svg'),
    );

    this.apiLocationsService
      .getMunicipality(this.userData.municipalityId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(municipality => (this.userMunicipality = municipality));

    this.translateService
      .get(TRANSLATIONS)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translations => {
        this.translations = translations;
      });

    // флаг, который выставляет по дефолту "Предствленность тематик" скрытой
    this.webStorageService.set(StorageKeys.DefaultEdFilters, true);
  }

  public ngOnInit(): void {
    this.b2gSaasService
      .getSchool()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((school: ISchool) => {
        this.school = school;
      });

    this.getReportData()
      .pipe(
        takeUntil(this.unsubscribe),
        // delay нужен, чтобы применились defaultFilters
        // нужно время для отрисовки canvas
        delay(50),
        tap(() => {
          this.setDefaultFilters();
        }),
      )
      .subscribe();

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => {
          this.setReport(ReportEnum.DEFAULT);
          this.setReport(ReportEnum.MUNICIPALITY);
        }),
      )
      .subscribe();
  }

  public setDefaultFilters(): void {
    const defaultEducationFilter = education.find(el => el.title == 'Хотят посещать (по убыванию)').value;
    this.educationFiltersForm.get('educationBaseFilter').setValue(defaultEducationFilter);
    this.educationFiltersForm.get('educationMunicipalityFilter').setValue(defaultEducationFilter);
  }

  public setFormControl(filter: { filterType: string; filterValue: string }): void {
    this.form.get(filter.filterType).value === filter.filterValue
      ? this.form.get(filter.filterType).setValue(null)
      : this.form.get(filter.filterType).setValue(filter.filterValue);

    switch (filter.filterType) {
      case FilterVariantsEnum.CLASS:
        this.form.get(FilterVariantsEnum.GENDER).setValue(null);
        break;

      case FilterVariantsEnum.GENDER:
        this.form.get(FilterVariantsEnum.CLASS).setValue(null);
        break;
    }
  }

  public getReportData(): Observable<any> {
    if (this.userData.regionId) {
      return forkJoin([
        this.apiResultsService.getSchoolReport(this.userData.schoolId),
        this.apiResultsService.getMunicipalityReport(this.userData.municipalityId),
      ]).pipe(
        tap(([report, municipalityReport]) => {
          if (report && report.report) {
            this.report = JSON.parse(JSON.stringify(report.report.interactiveReport));
            this.setReport(ReportEnum.DEFAULT);
          }
          if (report && report.comment && report.comment.includes('Cannot find report')) {
            this.reportNotFound = true;
          }
          if (municipalityReport && municipalityReport.report) {
            this.municipalityReport = JSON.parse(JSON.stringify(municipalityReport.report.municipalityInteractiveReport));
            this.setReport(ReportEnum.MUNICIPALITY);
          }
          this.isLoaded = true;
        }),
        catchError(err => {
          this.isLoaded = true;
          return throwError(err);
        }),
      );
    } else {
      return of(null);
    }
  }

  public setReport(type: ReportEnum): void {
    let report;

    if (type === ReportEnum.MUNICIPALITY) {
      report = this.municipalityReport;
    } else {
      report = this.report;
    }

    if (report) {
      let prefixFilter = 'ByGeneral';
      let postfixFilter = 'items';
      const genderFilter = this.form.get('genderFilter').value;
      const classFilter = this.form.get('classFilter').value;
      const isFilterApplied = genderFilter || classFilter;

      if (genderFilter === 'male' || genderFilter === 'female') {
        prefixFilter = 'ByGender';
        postfixFilter = genderFilter;
      } else if (classFilter) {
        prefixFilter = 'ByClasses';
        postfixFilter = classFilter;
      }

      const result = {
        keyIndicators: {
          profNavigationIndex: isFilterApplied
            ? report.plansAndExps.certainty[`certainty${prefixFilter}`][postfixFilter + 'Items'].profNavigationIndexCoef
            : report.plansAndExps.certainty[`certainty${prefixFilter}`].generalItems.profNavigationIndexCoef,
          professionsMatching: isFilterApplied
            ? report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter].professionsMatchingCoef
            : report.usersSelection.selectedProfessions[`professions${prefixFilter}`].professionsMatchingCoef,
        },
        institutionIndexes: isFilterApplied
          ? this.parseInstitutionData(report.plansAndExps.generalLevels[`generalLevels${prefixFilter}`][postfixFilter + 'Items'])
          : this.parseInstitutionData(report.plansAndExps.generalLevels[`generalLevels${prefixFilter}`].items),
        talentDistribution: isFilterApplied
          ? report.results.talents[`talents${prefixFilter}`][postfixFilter + 'Items']
          : report.results.talents[`talents${prefixFilter}`].generalItems,
        classesDistribution: isFilterApplied
          ? report.results.profilClasses[`profilClasses${prefixFilter}`][postfixFilter + 'Items']
          : report.results.profilClasses[`profilClasses${prefixFilter}`].generalItems,
        chartEducation: isFilterApplied
          ? this._setChart(
              report.plansAndExps.additionalEducation[`additionalEducation${prefixFilter}`][postfixFilter + 'Items'],
              ChartTypesEnum.EDUCATION,
              EducationChartTypesEnum.BASE,
            )
          : this._setChart(
              report.plansAndExps.additionalEducation[`additionalEducation${prefixFilter}`][postfixFilter],
              ChartTypesEnum.EDUCATION,
              EducationChartTypesEnum.BASE,
            ),
        chartSPO: isFilterApplied
          ? this._setChart(report.usersSelection.specialities[`specialities${prefixFilter}`][postfixFilter], ChartTypesEnum.SPO)
          : this._setChart(report.usersSelection.specialities[`specialities${prefixFilter}`], ChartTypesEnum.SPO),
        chartVPO: isFilterApplied
          ? this._setChart(report.usersSelection.specialities[`specialities${prefixFilter}`][postfixFilter], ChartTypesEnum.VPO)
          : this._setChart(report.usersSelection.specialities[`specialities${prefixFilter}`], ChartTypesEnum.VPO),
        profDemanded: {
          spo: isFilterApplied
            ? report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter].itemsSPO
            : report.usersSelection.selectedProfessions[`professions${prefixFilter}`].itemsSPO,
          vpo: isFilterApplied
            ? report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter].itemsVPO
            : report.usersSelection.selectedProfessions[`professions${prefixFilter}`].itemsVPO,
        },
        profPopular: isFilterApplied
          ? report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter].items
          : report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter],
        profNotPopular: isFilterApplied
          ? report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter].items
          : report.usersSelection.selectedProfessions[`professions${prefixFilter}`][postfixFilter],
      };

      if (type === ReportEnum.MUNICIPALITY) {
        this.viewMunicipalityData = result;
        this.viewMunicipalityData.totalSummary = report.indicators.totalSummary;
        this.viewMunicipalityData.weekSummary = report.indicators.weekSummaries;
      } else {
        this.viewData = result;
        this.viewData.totalSummary = report.indicators.totalSummary;
        this.viewData.weekSummary = report.indicators.weekSummaries;
      }

      this.updateCharts(type);
    } else return;

    this.cd.markForCheck();
  }

  public parseInstitutionData(dataObject): IAnswerPercents[] {
    const institutionIndexes = [];
    if (dataObject) {
      Object.keys(dataObject).forEach(key => {
        let institution = '';
        switch (key) {
          case institutionTypes.UNIVERSITY:
            institution = 'Вуз';
            break;
          case institutionTypes.COLLEGE:
            institution = 'Колледж';
            break;
          case institutionTypes.OTHER:
            institution = 'Затрудняюсь ответить';
        }
        institutionIndexes.push({ name: institution, percents: dataObject[key] });
      });
    }
    return institutionIndexes;
  }

  public downloadPupilsDistribution(distributionType: DistributionTypes): void {
    // данные для теста
    const classFilter = this.form.get('classFilter').value;
    const genderFilter = this.form.get('genderFilter').value;
    const genderFilterType: string = genderFilter === 'male' ? 'M' : 'F';
    const classFilterType: number = ClassVariants.find(classVariant => classVariant.key === classFilter)?.value;

    const dataObject: PupilsDistributionObject = {};
    Object.assign(dataObject, {
      schoolId: this.school.id,
      distributionType: distributionType,
      filterNumber: classFilterType,
      gender: genderFilterType,
    });

    this.apiUsersService
      .getPupilsByResultsFiltersExcelDirectors(dataObject)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (!response) {
          return;
        }
        const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
        const blob = new Blob([response.body], { type: response.headers.get('content-type') });
        const file = new File([blob], fileName, { type: response.headers.get('content-type') });
        saveAs(file);
      });
  }

  private _setHBarChart(dataset): IBarChart {
    const result = JSON.parse(JSON.stringify(defaultAdditionalChartData));
    result.data = dataset;
    result.options.scales.y.callback = function (tick) {
      tick = dataset.labels.length ? dataset.labels[tick] : tick;
      const maxLenStr = 12;
      tick = String(tick);
      const tickStr = tick;
      return tickStr.length > maxLenStr ? tick.substring(0, maxLenStr - 1) + '...' : tickStr;
    };

    return result;
  }

  private _setChart(
    specialities: SpecialitySelectionsByGeneral & AdditionalEducationItems,
    type: ChartTypesEnum,
    chartEducationLocal?: EducationChartTypesEnum,
  ): IBarChart {
    let wantedItems: any[], existingItems: any[], existingPaidItems: any[], labels: any[], educationFilter: any;

    switch (type) {
      case ChartTypesEnum.VPO:
        wantedItems = this.utilsService.dictionaryToChartConfig(specialities.wantedItemsVPO);
        existingItems = this.utilsService.dictionaryToChartConfig(specialities.existingItemsVPO);
        existingPaidItems = this.utilsService.dictionaryToChartConfig(specialities.existingItemsVPO);
        labels = Array.from(new Set(wantedItems.map(c => c.label).concat(existingItems.map(c => c.label))));
        break;

      case ChartTypesEnum.SPO:
        wantedItems = this.utilsService.dictionaryToChartConfig(specialities.wantedItemsSPO);
        existingItems = this.utilsService.dictionaryToChartConfig(specialities.existingItemsSPO);
        existingPaidItems = this.utilsService.dictionaryToChartConfig(specialities.existingItemsSPO);
        labels = Array.from(new Set(wantedItems.map(c => c.label).concat(existingItems.map(c => c.label))));
        break;

      case ChartTypesEnum.EDUCATION:
        wantedItems = this.utilsService.objectToChartConfig(specialities.wantedItems);
        existingItems = this.utilsService.objectToChartConfig(specialities.visitedItems);
        existingPaidItems = this.utilsService.objectToChartConfig(specialities.subjectsRepresentation);

        if (chartEducationLocal === EducationChartTypesEnum.MUNICIPALITY) {
          educationFilter = this.educationFiltersForm.get('educationMunicipalityFilter').value;
        } else {
          educationFilter = this.educationFiltersForm.get('educationBaseFilter').value;
        }

        labels = this.utilsService.getSortedFilterArray(educationFilter, {
          wantedItems,
          visitedItems: existingItems,
          subjectsRepresentation: existingPaidItems,
        });

        break;
    }

    const chart = {
      labels: labels,
      wantedItems: [],
      existingItems: [],
      existingPaidItems: [],
    };

    chart.labels.forEach(l => {
      const findIWI = wantedItems.find(el => el.label === l);
      if (findIWI) {
        chart.wantedItems.push(findIWI.count);
      } else {
        chart.wantedItems.push(0);
      }
      const findEI = existingItems.find(el => el.label === l);
      if (findEI) {
        chart.existingItems.push(findEI.count);
      } else {
        chart.existingItems.push(0);
      }
      const findEP = existingPaidItems.find(el => el.label === l);
      if (findEP) {
        chart.existingPaidItems.push(findEP.count);
      } else {
        chart.existingPaidItems.push(0);
      }
    });

    const data: any = JSON.parse(JSON.stringify(defaultChartData));
    this.fillChartData(data, chart, type);

    // set the canvas container height
    const numOfBars = data.labels.length;
    const maxHeightOfChart = 566;
    const minHeight = 35; // setting the min height of the bar + margin between
    const chartHeight = minHeight * numOfBars > maxHeightOfChart ? minHeight * numOfBars : maxHeightOfChart;

    switch (type) {
      case ChartTypesEnum.VPO:
        this.chartVPOheight = chartHeight.toString() + 'px';
        break;

      case ChartTypesEnum.SPO:
        this.chartSPOheight = chartHeight.toString() + 'px';
        break;

      case ChartTypesEnum.EDUCATION:
        this.chartEducationHeight = chartHeight.toString() + 'px';
        break;
    }

    const isEmpty = data.labels.length <= 0 || data.datasets.every(d => d.data.length <= 0);
    return isEmpty ? null : this._setHBarChart(data);
  }

  private fillChartData(data, chart, type: ChartTypesEnum): void {
    data.labels = chart.labels;

    data.datasets[0].label =
      type === ChartTypesEnum.EDUCATION
        ? this.translations['CHARTS.REPRESENTATIONS_OF_TOPICS.FULL']
        : this.translations['CHARTS.PLACES_BUDGET.FULL'];
    data.datasets[0].data = chart.existingItems;
    data.datasets[0].tooltipText =
      type === ChartTypesEnum.EDUCATION
        ? this.translations['COMMON.PLACES'].toLowerCase()
        : this.translations['COMMON.PLACES'].toLowerCase();
    // по дефолту будет значение hidden, далее - меняется по клику на легенду
    data.datasets[0].hidden = this.webStorageService.get(StorageKeys.DefaultEdFilters) === 'true' ? true : null;

    data.datasets[1].label =
      type === ChartTypesEnum.EDUCATION
        ? this.translations['CHARTS.WANT_TO_ATTEND.FULL']
        : this.translations['CHARTS.PLACES_NOT_BUDGET.FULL'];
    data.datasets[1].data = chart.existingItems;
    data.datasets[1].tooltipText =
      type === ChartTypesEnum.EDUCATION
        ? this.translations['COMMON.PEOPLES'].toLowerCase()
        : this.translations['COMMON.PLACES'].toLowerCase();

    data.datasets[2].label =
      type === ChartTypesEnum.EDUCATION ? this.translations['CHARTS.VISIT_NOW.FULL'] : this.translations['CHARTS.POTENTIALITY_ARRIVE.FULL'];
    data.datasets[2].data = chart.wantedItems;
    data.datasets[2].tooltipText =
      type === ChartTypesEnum.EDUCATION
        ? this.translations['COMMON.PEOPLES'].toLowerCase()
        : this.translations['COMMON.PEOPLES'].toLowerCase();
  }

  private parseFilenameFromContentDisposition(contentDisposition): string {
    if (!contentDisposition) {
      return null;
    }
    const matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  /* Т.к. графики рисуются на канвасе, при изменении данных он не всегда перерисовывается. */
  public updateCharts(type: ReportEnum): void {
    if (type === ReportEnum.MUNICIPALITY) {
      this.isUpdatingMunicipality = true;
      setTimeout(() => (this.isUpdatingMunicipality = false), 20);
    } else {
      this.isUpdatingBase = true;
      setTimeout(() => (this.isUpdatingBase = false), 20);
    }
  }

  public toggleMunicipalityCharts(): void {
    this.showMunicipityReport = !this.showMunicipityReport;
    if (this.showMunicipityReport) {
      setTimeout(() => {
        this.setCircleBarComparisonHtml();
        this.setCharTreeMapComparisonHtml();
      }, 100);
    }
  }

  // set HTML elements for data comparison
  public setCircleBarComparisonHtml(): void {
    const progressCircleArray = window.document.getElementsByTagName('prf-progress-circle');

    const arrayLength = progressCircleArray.length;
    const municipalityCircleBarArray: any[] = [];

    for (let i = 0; i < arrayLength; i++) {
      const el = progressCircleArray[i];
      this.municipalityProgressBars.nativeElement.contains(el) ? municipalityCircleBarArray.push(el) : null;
    }
    const professionsMatchingEl = municipalityCircleBarArray[0];
    const profNavigationEl = municipalityCircleBarArray[1];

    const profNavigationComparisonHTML = document.createElement('div');
    const profNavigationData = Math.round(
      this.viewData.keyIndicators.profNavigationIndex - this.viewMunicipalityData?.keyIndicators.profNavigationIndex || 0,
    );
    const profNavigationColor = '#36CA75';
    profNavigationComparisonHTML.innerHTML = profNavigationInnerHTML(profNavigationData, profNavigationColor);
    if (profNavigationEl) {
      profNavigationEl.appendChild(profNavigationComparisonHTML);
    }

    const professionsMatchingData = Math.round(
      this.viewData.keyIndicators.professionsMatching - this.viewMunicipalityData?.keyIndicators.professionsMatching || 0,
    );
    const professionsMatchingColor = '#ff5722';
    const professionsMatchingComparisonHTML = document.createElement('div');
    professionsMatchingComparisonHTML.innerHTML = professionsMatchingInnerHTML(professionsMatchingData, professionsMatchingColor);
    if (professionsMatchingEl) {
      professionsMatchingEl.appendChild(professionsMatchingComparisonHTML);
    }
  }

  public setCharTreeMapComparisonHtml(): void {
    const chartTreeMapArray = window.document.getElementsByTagName('prf-chart-tree-map');

    const arrayLength = chartTreeMapArray.length;
    const municipalityTalentDistributionArray: any[] = [];

    for (let i = 0; i < arrayLength; i++) {
      const el = chartTreeMapArray[i];
      this.municipalityProgressBars.nativeElement.contains(el) ? municipalityTalentDistributionArray.push(el) : null;
    }

    const talentDistributionEl = municipalityTalentDistributionArray[0];

    const talentDistributionComparisonHTML = document.createElement('div');
    const talentDistributionValuesArray = [];
    const talentDistributionKeysArray = [];
    if (this.viewMunicipalityData?.talentDistribution) {
      for (const key of this.viewMunicipalityData.talentDistribution) {
        talentDistributionValuesArray.push(this.viewMunicipalityData.talentDistribution[key].percent);
      }

      talentDistributionValuesArray.forEach(percentValue => {
        talentDistributionKeysArray.push(
          Object.keys(this.viewMunicipalityData.talentDistribution).find(key => {
            return this.viewMunicipalityData.talentDistribution[key].percent == percentValue;
          }),
        );
      });
    }

    const talentDistributionDataSet = talentDistributionKeysArray.map(key => {
      return Math.round(
        parseInt(this.viewMunicipalityData.talentDistribution[key].percent, 10) -
          parseInt(this.viewData.talentDistribution[key].percent, 10),
      );
    });

    const talentDistributionColorSet = ['#FF7AED', '#7852FB', '#36CA75', '#FF5722', '#FDCF00', '#3E60FD', '#13E5DB', '#9375FB'];
    talentDistributionComparisonHTML.innerHTML = talentDistributionInnerHTML(talentDistributionDataSet, talentDistributionColorSet);
    if (talentDistributionEl) {
      talentDistributionEl.appendChild(talentDistributionComparisonHTML);
    }
  }

  @HostListener('window:scroll')
  public onScroll(): void {
    if (this.viewData) {
      const containerOffSetTop = this.contentContainer.nativeElement.getBoundingClientRect().top;

      if (containerOffSetTop < 120 && !this.isMenuFixed) {
        this.isMenuFixed = true;
      } else if (containerOffSetTop > 120 && this.isMenuFixed) {
        this.isMenuFixed = false;
      }
    }
  }
}
