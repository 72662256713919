import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subject, timer } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import {
  ApiAuthService,
  ApiLocationsService,
  ApiProfilesService,
  B2gSaasService,
  IUserInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-user-profile-outlet',
  templateUrl: './user-profile-outlet.component.html',
  styleUrls: ['./user-profile-outlet.component.scss'],
})
export class UserProfileOutletComponent implements OnInit, OnDestroy {
  CHANGE_PASS_RU = [
    {
      en: 'Incorrect password.',
      ru: '',
    },
    { en: 'Passwords must be at least 6 characters.', ru: 'Пароль должен быть не менее 6 символов.' },
    {
      en: "Passwords must have at least one lowercase ('a'-'z').",
      ru: "Пароль должен иметь по крайней мере одину букву ('a'-'z').",
    },
    {
      en: "Passwords must have at least one digit ('0'-'9').",
      ru: "Пароль должен иметь как минимум одну цифру ('0'-'9').",
    },
  ];

  school: any;
  municipality: any;
  userClass: any;
  userProfile: IUserInfo;
  editProfile: boolean = false;
  parents: Array<any> = [];
  showInfoPopup: boolean = false;
  userRole: string;
  showInviteParent: boolean = false;
  uploadImageError: string = '';
  password: string = '';
  errorChangePass: string = '';
  form: UntypedFormGroup;
  submitted: boolean;
  userBirthday: string = '';
  animIndex: number = 0;

  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public isMaskedPassword: boolean = true;
  public isMaskedPasswordOld: boolean = true;
  public rusLettersError: boolean = false;
  public focusOutPasswordErrors: boolean = false;
  public charactersError: boolean = true;
  public letterError: boolean = true;
  public whiteSpaceError: boolean = false;
  public numberError: boolean = true;
  errorOldPass: string = '';

  public mosruUser: boolean = false;

  public passwordError: boolean = false;
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private apiAuthService: ApiAuthService,
    private fb: UntypedFormBuilder,
    private apiProfilesService: ApiProfilesService,
    private pageHeaderService: PageHeaderService,
    private apiLocationsService: ApiLocationsService,
    private router: Router,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Профайл' });
    this.mosruUser = this.webStorageService.get(StorageKeys.Tag) === 'MosRu';

    this.getTranslation('SHARED.INCORRECT_OLD_PASSWORD')
      .pipe(take(1))
      .subscribe(translation => (this.CHANGE_PASS_RU[0].ru = translation));
  }

  ngOnInit() {
    this.userProfile = this.userDataHandlerService.getUserData().getValue();

    if (this.userProfile) {
      this.userBirthday = DateHelper.toDayJsInUTC(this.userProfile.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }

    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    if (this.userRole === 'schooladmin' || this.userRole === 'director') {
      forkJoin([this.b2gSaasService.getSchool(), this.apiLocationsService.getMunicipality(localStorage.getItem('municipalityId'))])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(([school, municipality]) => {
          this.school = school;
          this.municipality = municipality;
        });
    }

    this.form = this.fb.group({
      password: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
      passwordOld: new UntypedFormControl(this.password, [Validators.maxLength(256)]),
    });
  }

  cancelEdit() {
    this.form.get('password').setValue('');
    this.form.get('password').updateValueAndValidity();
    this.form.get('passwordOld').setValue('');
    this.form.get('passwordOld').updateValueAndValidity();
  }

  get f() {
    return this.form.controls;
  }

  submitChanges() {
    this.submitted = true;

    if (this.form.valid) {
      if (this.form.value.password !== '' || this.form.value.passwordOld !== '') {
        if (!this.form.value.password || !this.form.value.passwordOld) {
          this.errorChangePass = 'Для смены пароля, введите старый и новый пароль.';
        } else if (this.form.value.password === this.form.value.passwordOld) {
          this.errorChangePass = 'Новый пароль не должен совпадать со старым';
        } else {
          this.updatePassword();
        }
      }
    } else {
      return;
    }
  }

  updatePassword() {
    const model = {
      currentPassword: this.form.value.passwordOld,
      newPassword: this.form.value.password,
      newPassword2: this.form.value.password,
    };

    this.errorChangePass = '';

    if (!this.whiteSpaceError) {
      this.b2gSaasService
        .changeUserPassword(model)
        .pipe(take(1))
        .subscribe(changeResult => {
          if (changeResult) {
            if (changeResult.status === 'Success') {
              this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
              this.router.navigate(['/login']);
            } else {
              if (changeResult.comment) {
                if (changeResult.comment.includes('Incorrect password')) {
                  this.errorOldPass = 'Неверный старый пароль.';
                } else {
                  this.errorChangePass = this.translateChangePassRequest(changeResult.comment);
                }
              }
            }
          }
        });
    }
  }

  private translateChangePassRequest(text: string) {
    const findText = this.CHANGE_PASS_RU.filter(cp => cp.en === text);

    if (findText && findText.length) {
      return findText[0].ru;
    } else {
      return text;
    }
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public toggleMaskOld() {
    this.isMaskedPasswordOld = !this.isMaskedPasswordOld;
  }

  public errorChecking(): boolean {
    if (this.passwordError) {
      return true;
    }
  }

  private isWhiteSpace(event) {
    this.whiteSpaceError = !this.testWhiteSpace.test(event);
  }

  public focusOutErrorChecking() {
    if (this.form.value.password != '') {
      this.focusOutPasswordErrors =
        this.charactersError || this.letterError || this.numberError || this.rusLettersError || this.whiteSpaceError;
    }
  }

  private testPassword(event): boolean {
    this.passwordError = false;

    this.whiteSpaceError = false;
    this.charactersError = true;
    this.letterError = true;
    this.numberError = true;
    this.rusLettersError = false;
    if (event && event.length > 0) {
      this.isWhiteSpace(event);
      this.charactersError = !this.testSixCharter.test(event);
      this.letterError = !this.testOneLetter.test(event);
      this.numberError = !this.testOneDigit.test(event);
      this.rusLettersError = this.testRusLetters.test(event);
      if ((this.whiteSpaceError = !this.testWhiteSpace.test(event))) {
        return false;
      }
      if ((this.charactersError = !this.testSixCharter.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.letterError = !this.testOneLetter.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.numberError = !this.testOneDigit.test(event))) {
        this.passwordError = true;
        return false;
      }
      if ((this.rusLettersError = this.testRusLetters.test(event))) {
        this.passwordError = true;
        return false;
      }
      return true;
    }
  }

  public clearPassError() {
    this.errorChangePass = '';
    this.errorOldPass = '';
    this.focusOutPasswordErrors = false;
  }

  getMale(isMale: boolean) {
    if (this.userProfile.gender == 'M') {
      return true;
    } else {
      return false;
    }
  }

  public isAccessAllowed() {
    return this.testPassword(this.form.value.password);
  }

  changeIndex(animIndex: number) {
    if (this.animIndex == 0 || this.animIndex == 1) {
      this.animIndex = 2;
    } else {
      this.animIndex = 1;
    }
    return animIndex;
  }

  indexFromChild(event) {
    this.animIndex = event;
  }

  public logOut() {
    // this.pageHeaderService.closeProfile();
    this.apiAuthService.logout();
    this.webStorageService.clearAll();
    this.router.navigate(['']);
  }

  inviteParent() {
    this.showInviteParent = true;
  }

  inviteClose() {
    this.showInviteParent = false;
  }

  selectFile(event) {
    this.uploadImageError = '';

    if (!event.target.files.item(0).type.match('image.*')) {
      this.uploadImageError = 'Неверный формат файла.';
      return;
    }

    if (event.target.files.item(0).size > 1024 * 1024) {
      this.uploadImageError = 'Размер не болле: 1 МB';
      return;
    }

    this.userProfile.imagePath = '';

    this.apiProfilesService
      .changeUserAvatar(event.target.files)
      .pipe(switchMap(() => timer(3000).pipe(tap(() => {
        this.userProfile = this.userDataHandlerService.getUserData().getValue();
        this.parents = this.userProfile && this.userProfile.parents && this.userProfile.parents.length ? this.userProfile.parents : [];

        this.userBirthday = DateHelper.toDayJsInUTC(this.userProfile.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY'); // забираем дату из userProfile и переводим в формат DD/MM/YYYY
        if (!this.userBirthday || this.userBirthday == 'Invalid date') {
          this.userBirthday = 'Дата не указана';
        }
      }))))
      .subscribe();
  }

  getImageUrl(user: any) {
    return user.imagePath ? user.imagePath : './profilum-assets/images/icons/no-photo.svg';
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
