import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'app/shared/modules/material.module';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { PagesModule } from 'app/pages/pages.module';
import { LandingBaseRoutingModule } from './landing-base-routing.module';
import { LandingBaseComponent } from './landing-base/landing-base.component';
import { LoginComponent } from './login/login.component';
import { RegistrationPupilComponent } from './registration/registration-pupil/registration-pupil.component';
import { AppsettingsModule, AppSettingsService, B2gSaasService } from '@profilum-library';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';
import { CaptchaModule } from 'app/shared/common-components/captcha/captcha.module';
import { DynamicLabelModule } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.module';
import { PhoneMaskModule } from '../../shared/directives/phone-mask/phone-mask.module';
import { RegistrationChangePasswordModule } from './registration/registration-change-password/registration-change-password.module';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    PagesModule,
    SharedModule,
    LandingBaseRoutingModule,
    RegistrationChangePasswordModule,
    CaptchaModule,
    DynamicLabelModule,
    SwiperDirective,
    PhoneMaskModule,
    NgOptimizedImage,
  ],
  declarations: [LandingBaseComponent, LoginComponent, RegistrationPupilComponent],
  exports: [LandingBaseComponent],
  providers: [FilterByNamePipe, B2gSaasService, AppSettingsService, AppsettingsModule.init()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingBaseModule {}
